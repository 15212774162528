import React, { Component } from "react";
//import { Auth } from "aws-amplify";
import { withAuthenticator } from "aws-amplify-react";
import { Container } from 'semantic-ui-react'
//import { Elements, StripeProvider, CardElement } from "react-stripe-elements";

import StripeDropdown from "../components/StripeDropdown"
import TagSteps from "../containers/TagSteps"
import aws_exports from '../aws-exports';
import "../css/Credits.css";

class Credits extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stripe: null,
    };
  }

  componentDidMount() {
    if(!document.getElementById('stripeJs')) {
      // componentDidMount only runs in a browser environment.
      // In addition to loading asynchronously, this code is safe to server-side render.

      // You can inject a script tag manually like this,
      // or you can use the 'async' attribute on the Stripe.js v3 <script> tag.
      const stripeJs = document.createElement('script');
      stripeJs.src = 'https://js.stripe.com/v3/';
      stripeJs.async = true;
      stripeJs.id = 'stripeJs';
      stripeJs.onload = () => {
        if (!this.state.stripe) {
          // The setTimeout lets us pretend that Stripe.js took a long time to load
          // Take it out of your production code!
          setTimeout(() => {
            this.setState({
              stripe: window.Stripe(aws_exports.stripe_key),
            });
          }, 500);
        }
      };
      document.body && document.body.appendChild(stripeJs);
    } else {
      if (!this.state.stripe) {
        // The setTimeout lets us pretend that Stripe.js took a long time to load
        // Take it out of your production code!
        setTimeout(() => {
          this.setState({
            stripe: window.Stripe(aws_exports.stripe_key),
          });
        }, 500);
      }
    }
  }

  componentWillUnmount() {
    this.state.stripe._controller._controllerFrame._removeAllListeners()
    this.state.stripe._controller._controllerFrame._iframe.remove()
//    var stripeJs = document.getElementById('stripeJs');
//    stripeJs && stripeJs.parentNode && stripeJs.parentNode.removeChild(stripeJs);

    var cframe = this.state.stripe && this.state.stripe._controller && this.state.stripe._controller._controllerFrame;
    cframe && cframe._removeAllListeners();

    const stripeIframes = [
      document.querySelectorAll('[name^=__privateStripeMetricsController]'),
      document.querySelectorAll('[name^=__privateStripeController]')
    ]

    stripeIframes.forEach(iframes =>
      iframes.forEach(iframe => {
        iframe.parentNode.removeChild(iframe)
      })
    )
  }

  render() {
    const imgCount = this.props.selectedFiles ? this.props.selectedFiles.length : 0;
    const creditCount = this.props.user ? this.props.user.credits : 0;

    return (
      <>
      <TagSteps active='Credits' user={this.props.user} imageCount={imgCount} />
      <Container className='blabla' textAlign='center' >
        <p />
        <p>You have selected {imgCount} images to tag. You have {creditCount} credits available.</p>
        { imgCount <= creditCount &&
        <p>Click {imgCount > 0 ? 'next to start or' : ''} back to select more images</p>}
        { imgCount > creditCount &&
        <><p>You don't have enough credits to continue with your current selection. </p><StripeDropdown userId={this.props.user.id} email={this.props.user.email} /></>}
      </Container>

      </>
    );
  }
}

export default withAuthenticator(Credits)


export function compareValues(key, order='asc') {
  return function(a, b) {
    if(!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = (typeof a[key] === 'string') ?
      a[key].toUpperCase() : a[key];
    const varB = (typeof b[key] === 'string') ?
      b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    );
  };
}

export function arrayAddSubtract(add, currentArray, newArray) {
  var resultArray = currentArray.slice();

  if (add) {
    newArray.forEach(item => {
      if (!resultArray.includes(item)) {
        resultArray.push(item);
      }
    })
  } else {
    newArray.forEach(item => {
      var index = resultArray.indexOf(item);
      if (index > -1) {
        resultArray.splice(index, 1);
      }
    })
  }

  return resultArray;
}

export function promiseAllProgress(context, promises, progress_cb) {
  let d = 0;
  progress_cb(context, 0);
  for (const p of promises) {
    // eslint-disable-next-line
    p.then(()=> {
      d ++;
//        progress_cb(this, (d * 100) / proms.length );
      progress_cb(context, d);
    });
  }
  return Promise.all(promises);
}

export function ddbTTL(days) {
  const now = Math.floor(new Date() / 1000);
  const ttl = 86400 * days;
  return now + ttl;
}

import React, { Component } from "react";
import { Storage } from "aws-amplify";
import { Link } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify';
import { withAuthenticator } from "aws-amplify-react";
import { Table, Menu } from 'semantic-ui-react'

import StripeDropdown from "../components/StripeDropdown"
import { getTransactions, getStripePayments } from '../components/queries'
import "../css/MyAccount.css";

class MyAccount extends Component {
  tranLimit = 50;
  stripeLimit = 50;
  tranVisibleLimit = 5;
  stripeVisibleLimit = 5;
  loadingTransactions = false;
  loadingStripes = false;

  constructor(props) {
    super(props);

    this.state = {
      downloads: [],
      transactions: [],
      visibleTransactions: [],
      tranToken: null,
      stripes: [],
      visibleStripes: [],
      stripeToken: null,
    };

    this.checkDownloads();
    this.loadStripes(this.stripeLimit);
  }

  componentDidUpdate() {
    if (this.state.tranToken && this.state.transactions.length < this.tranVisibleLimit)
      this.loadTransactions(this.tranLimit, this.state.tranToken);

    if (this.state.stripeToken && this.state.stripes.length < this.stripeVisibleLimit)
      this.loadStripes(this.stripeLimit, this.state.stripeToken);
  }

  // My Account Section
  renderMyAccountTable() {
    const tcels = 1;
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={tcels}>My Account</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'><Link to='/mypreferences'>My Preferences</Link></Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.Cell><b>Email Address:</b> {this.props.user.email}</Table.Cell>
            <Table.Cell><b>Available Credits:</b> {this.props.user.credits} - <StripeDropdown userId={this.props.user.id} email={this.props.user.email} /></Table.Cell>
          </Table.Row>
        </Table.Header>
      </Table>
    )
  }

  // Transactions Section
  checkDownloads() {
    return Storage.list('downloads/', { level: 'private' })
    .then(result => {
      this.setState({ downloads: result });
      this.loadTransactions(this.tranLimit);
    })
    .catch(err => console.log(err));
  }

  loadTransactions(limit, nextToken) {
    if (this.loadingTransactions) return;

    this.loadingTransactions = true;

    return API.graphql(graphqlOperation(getTransactions, {
      id: this.props.user.id,
      limit: limit,
      nextToken: nextToken
    }))
    .then(res => {
      var promises = [];
      res.data.listPicatags.items.forEach(tran => {
        var s3Object = this.state.downloads.find(file => file.key === `downloads/${tran.sessionId}.zip`);
        if (s3Object) {
          //console.log("need link for " + s3Object.key);
          promises.push(Storage.get(`downloads/${tran.sessionId}.zip`, { level: 'private' })
          .then(res => {
            tran.link = res;
          }));
        }
      });

      return Promise.all(promises)
      .then(data => {
        return res;
      });
    })
    .then(res => {
      this.setState(state => {
        var t = state.transactions.concat(res.data.listPicatags.items);

        return {
          transactions: t,
          tranToken: res.data.listPicatags.nextToken
        }
      });

      if (this.state.visibleTransactions.length < this.tranVisibleLimit &&
      this.state.visibleTransactions.length < this.state.transactions.length) {
        this.handleTranLoadMore();
      }

      this.loadingTransactions = false;
    })
  }

  handleTranLoadMore = () => {
    if (this.state.transactions.length > 0) {
      var num = this.state.transactions.length;
      if (num > this.tranVisibleLimit) num = this.tranVisibleLimit;

      var i;
      this.setState(state => {
        var t = state.transactions;
        var v = state.visibleTransactions;

        for (i = 0; i < num; i++) {
          v.push(t.shift());
        }

        return { transactions: t, visibleTransactions: v }
      });
    }
  }

  renderTranTableRows() {
    var rows = [];
    this.state.visibleTransactions.forEach(tran => {
      var date = new Date(Date.parse(tran.updatedAt));

      rows.push(
        <Table.Row key={tran.sk}>
          <Table.Cell>{date.toLocaleString()}</Table.Cell>
          <Table.Cell>{tran.description}</Table.Cell>
          <Table.Cell textAlign='right'>{tran.credits}</Table.Cell>
          <Table.Cell>{tran.link && <a href={tran.link}>{tran.sessionId}.zip</a>}</Table.Cell>
        </Table.Row>
      )
    })

    return rows;
  }

  renderTranTable() {
    const tcels = 4;
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={tcels}>Recent Transactions</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Credits</Table.HeaderCell>
            <Table.HeaderCell>Download Link</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
        {this.renderTranTableRows()}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell textAlign='right' colSpan={tcels}>{this.state.transactions.length > 0 &&
              <Menu floated='right'>
                <Menu.Item as="a" onClick={this.handleTranLoadMore}>Load More</Menu.Item>
              </Menu>
            }</Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    )
  }

  // Stripe Section
  loadStripes(limit, nextToken) {
    if (this.loadingStripes) return;

    this.loadingStripes = true;

    return API.graphql(graphqlOperation(getStripePayments, {
      id: this.props.user.id,
      limit: limit,
      nextToken: nextToken
    }))
    .then(res => {
      this.setState(state => {
        var s = state.stripes.concat(res.data.listPicatags.items);

        return {
          stripes: s,
          stripeToken: res.data.listPicatags.nextToken
        }
      });

      if (this.state.visibleStripes.length < this.stripeVisibleLimit &&
      this.state.visibleStripes.length < this.state.stripes.length) {
        this.handleStripeLoadMore();
      }
    })
    .then(res => {
      this.loadingStripes = false;
    })
  }

  handleStripeLoadMore = () => {
    if (this.state.stripes.length > 0) {
      var num = this.state.stripes.length;
      if (num > this.stripeVisibleLimit) num = this.stripeVisibleLimit;

      var i;
      this.setState(state => {
        var t = state.stripes;
        var v = state.visibleStripes;

        for (i = 0; i < num; i++) {
          v.push(t.shift());
        }

        return { stripes: t, visibleStripes: v }
      });
    }
  }

  renderStripeTableRows() {
    var rows = [];
    this.state.visibleStripes.forEach(stripe => {
      var date = new Date(Date.parse(stripe.updatedAt));

      rows.push(
        <Table.Row key={stripe.sk}>
          <Table.Cell>{date.toLocaleString()}</Table.Cell>
          <Table.Cell textAlign='right'>€{(stripe.amountCents/100).toFixed(2)}</Table.Cell>
          <Table.Cell textAlign='right'>{stripe.credits}</Table.Cell>
        </Table.Row>
      )
    })

    return rows;
  }

  renderStripeTable() {
    const tcels = 3;
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={tcels}>Recent Payments</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.HeaderCell>Credits</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
        {this.renderStripeTableRows()}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell textAlign='right' colSpan={tcels}>{this.state.stripes.length > 0 &&
              <Menu floated='right'>
                <Menu.Item as="a" onClick={this.handleStripeLoadMore}>Load More</Menu.Item>
              </Menu>
            }</Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    )
  }

  render() {
    return (
      <>
      {this.renderMyAccountTable()}
      {this.renderTranTable()}
      {this.renderStripeTable()}
      </>
    );
  }
}

export default withAuthenticator(MyAccount)

import React, { Component } from "react";
import { Link } from 'react-router-dom'
import { Button, Form, Message, Divider, Container } from 'semantic-ui-react'
import "../css/SignInUp.css";
import { Auth } from "aws-amplify";
import { API, graphqlOperation } from 'aws-amplify';
import { logTransaction } from "../components/LogTransaction"
import { createUser } from '../components/queries'

export default class SignInUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 'signin',
      isLoading: false,
      confirmationCode: '',
      name: '',
      email: '',
      password: '',
      confirmPassword: ''
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  validateSignupForm() {
    return (this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword &&
      this.state.name.length > 0 &&
      this.state.acceptTerms === true);
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  handleTermsChange = event => {
    this.setState({
      [event.target.id]: event.target.checked
    });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSignInSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true, error: false, errorMessage: '' });

    try {
      const user = await Auth.signIn(this.state.email, this.state.password);
      await this.props.userHasAuthenticated(true, user.attributes.sub);
    } catch (e) {
      console.log(e);
      if (e.code === 'UserNotFoundException') {
        this.setState({ page: 'signup' })
      } else if (e.code === 'UserNotConfirmedException') {
        this.setState({ page: 'confirm' })
      }
      this.setState({ isLoading: false, error: true, errorMessage: e.message });
    }
  }

  handleSignupSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true, error: false, errorMessage: '' });
    try {
      const newUser = await Auth.signUp({
        username: this.state.email,
        password: this.state.password,
        attributes: {
          name: this.state.name
        }
      });
      this.setState({
        newUser: newUser,
        page: 'confirm'
      });
    } catch (e) {
      this.setState({ isLoading: false, error: true, errorMessage: e.message });
    }
    this.setState({ isLoading: false });
  }

  handleConfirmationSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true, success: false, successMessage: '', error: false, errorMessage: '' });
    try {
      await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
      const user = await Auth.signIn(this.state.email, this.state.password);
      const userInfo = await Auth.currentUserInfo();

      await this.createUser(user.attributes.sub, userInfo.id, user.attributes.email);
      await this.props.userHasAuthenticated(true, user.attributes.sub);
      this.props.history.push("/");
    } catch (e) {
      console.log(JSON.stringify(e, null, 2));
      this.setState({ isLoading: false, success: false, error: true, errorMessage: e.message });
    }
  }

  resendCode = async event => {
    this.setState({ isLoading: true, success: false, successMessage: '', error: false, errorMessage: '' });
    Auth.resendSignUp(this.state.email).then(() => {
      this.setState({
        isLoading: false,
        success: true,
        emailSent: true,
        successMessage: 'A new confirmation code was sent to ' + this.state.email
      })
    }).catch(e => {
      this.setState({ isLoading: false, success: false, error: true, errorMessage: e.message });
    });
  }

  gotoSignIn = async event => {
    event.preventDefault();
    this.setState({ page: 'signin' })
  }

  gotoSignup = async event => {
    event.preventDefault();
    this.setState({ page: 'signup' })
  }

  async createUser(userId, idid, email) {
    await API.graphql(graphqlOperation(createUser, {
      input: {
        id:  userId,
        sk: "User",
        idid: idid,
        email: email,
        credits: 0,
      }}
    ))

    // new user credits
    // TODO: move the sign up credits to some param....
    await logTransaction(userId, 100, "New User SignUp");
  }


  renderSignInForm() {
    return (
      <Container text>
      <h2>Sign In</h2>
      <Form className="SignIn" onSubmit={this.handleSignInSubmit} loading={this.state.isLoading} error={this.state.error}>
        <Form.Input label='Email' type='email' placeholder='Email' id="email"
          onChange={this.handleChange} value={this.state.email} autoFocus />
        <Form.Input label='Password' type='password' placeholder='Password'
          id="password" onChange={this.handleChange} value={this.state.password} />
        <Button type='submit' disabled={!this.validateForm()}>Sign In</Button>
        <Message error header='Sorry' content={this.state.errorMessage} />
        <Divider />
        <Container textAlign='center'><Link to='/signin/reset'>Forgot your password?</Link> - No account yet? <Link to='' onClick={this.gotoSignup}>Create one!</Link></Container>
      </Form>
      </Container>
    );
  }

  renderSignUpForm() {
    return (
      <Container text>
      <h2>Sign Up</h2>
      <Form className="Signup" onSubmit={this.handleSignupSubmit} loading={this.state.isLoading} error={this.state.error}>
        <Form.Input label='Name' type='text' placeholder='Name' id="name"
          onChange={this.handleChange} value={this.state.name} autoFocus />
        <Form.Input label='Email' type='email' placeholder='Email' id="email"
          onChange={this.handleChange} value={this.state.email} />
        <Form.Input label='Password' type='password' placeholder='Password'
          id="password" onChange={this.handleChange} value={this.state.password} />
        <Form.Input label='Confirm Password' type='password' placeholder='Password'
          id="confirmPassword" onChange={this.handleChange} value={this.state.confirmPassword} />
        <Form.Checkbox id="acceptTerms" onChange={this.handleTermsChange} checked={this.state.acceptTerms} label='I have read and agree to the Terms and Conditions' />
        <Button type='submit' disabled={!this.validateSignupForm()}>Sign Up</Button>
        <Message error header='Sorry' content={this.state.errorMessage} />
        <Divider />
        <Container textAlign='center'><Link to='/signin/reset'>Forgot your password?</Link> - Already have an account? <Link to='' onClick={this.gotoSignIn}>Sign in here!</Link></Container>
      </Form>
      </Container>
    );
  }

  renderConfirmationForm() {
    return (
      <Container text>
      <h2>Verify you Email</h2>
      <Form className="Signup" onSubmit={this.handleConfirmationSubmit}
      loading={this.state.isLoading} error={this.state.error} success={this.state.success}>
        <Form.Input label='Confirmation Code' type='tel' id="confirmationCode"
          onChange={this.handleChange} value={this.state.confirmationCode} autoFocus />
        <Button type='submit' disabled={!this.validateConfirmationForm()}>Verify</Button>
        <Button disabled={this.state.emailSent} type="button" onClick={this.resendCode}>Resend Code</Button>
        <Message error header='Sorry' content={this.state.errorMessage} />
        <Message success header='On its way!' content={this.state.successMessage} />
      </Form>
      </Container>
    );
  }

  render() {
    if (this.state.page === 'signup') {
      return (
         this.renderSignUpForm()
      );
    } else if (this.state.page === 'confirm') {
      return (
         this.renderConfirmationForm()
      );
    } else {
      return (
         this.renderSignInForm()
      );
    }
  }
}

import React, { Component } from "react";
import { Container } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import "../css/Home.css";

export default class Terms extends Component {

  render() {
    return (
      <>
      <Container text>
        <h2>Terms and Conditions</h2>
        <h3>1 About these terms</h3>
        <b>1.1</b> These terms apply to your download, access and/or use of
          Picatag, whether on your computer, on a mobile device, on our website
          picatag.com (the "Website") or any other website, device or
          platform (each an "App" and together the "Apps"). These terms also
          apply to any other services that we may provide in relation to the
          Apps or the Website, such as customer support, social media, community
          channels and other websites that we may operate from time to time. We
          refer to all our Apps and other services collectively as the
          "Services" in these terms. These terms are a legal agreement and
          contain important information about your rights and obligations in
          relation to our Services.<br />
        <b>1.2</b> If you do not agree to these terms or any future updated
          version of them then you must not access and/or use, and must cease
          all access and/or use of, any of our Services. If we require that any
          future update to these terms requires any action from you in order to
          accept the updated terms, then you may not be able to continue to use
          the Services until you have taken such action.<br />
        <b>1.3</b> These terms represent a legal agreement between you and
          Picatag.<br />
        <b>1.4</b> In these terms references to "Picatag", "we", "us" and "our"
          are references to Picatag.<br />
        <b>1.5</b> These terms are made available via the app store or platform
          that you download our Apps from (such as the Apple App Store, Google
          Play Store, Amazon App Store or Windows Phone Store), and on our
          website at picatag.com (the “Website”). You agree that by
          accessing and/or using our Services, you are agreeing to these terms
          and our Privacy Policy.<br />
        <b>1.6</b> For use of our Services, you agree that you are at least 18
          years old.<br />
        <b>1.7</b> You can access the latest version of these terms at any time
          at picatag.com/terms. We can make changes to these terms at any time
          in accordance with paragraph 14 below, and your continued use of our
          Services after the terms have been updated shall confirm your
          acceptance of the updated terms.<br />
        <h3>2 About accessing and using our Services</h3>
        <b>2.1</b> You are responsible for the internet connection and/or mobile
          charges that you may incur for accessing and/or using our Services.
          You should ask your mobile operator or internet service provider if
          you are unsure what these charges will be, before you access and/or
          use our Services.<br />
        <b>2.2</b> There may be times when our Services or any part of them are
          not available for technical or maintenance related reasons, whether on
          a scheduled or unscheduled basis.<br />
        <h3>3 Accounts</h3>
        <b>3.1</b> When using our Services you may choose to, and in some
          instances you will be required to, create an account with us. If you
          do create an account with us, you agree that you shall take all steps
          necessary to protect your log in details and keep them secret.<br />
        <b>3.2</b> You agree that you shall not give your log in details to
          anyone else or allow anyone else to use your log in details or
          account.<br />
        <b>3.3</b> We will be entitled to assume that anyone signing into your
          account using your log in details is either you or someone signing in
          with your permission. If you fail to keep your sign in details secret,
          or if you share your sign in details or account with someone else
          (whether intentionally or unintentionally), you accept full
          responsibility for the consequences of this (including any
            unauthorized purchases) and agree to fully compensate us for any
            losses or harm that may result.<br />
        <b>3.4</b> We will not be responsible to you for any loss that you
          suffer as a result of an unauthorised person accessing your account
          and/or using our Services and we accept no responsibility for any
          losses or harm resulting from its unauthorised use, whether
          fraudulently or otherwise.<br />
        <b>3.5</b> We reserve the right to delete your account if no activity is
          conducted by you in relation to the account for two or more years. In
          such event, you may no longer be able to access and/or use any Virtual
          Money (as defined below) associated with that account and no refund
          will be offered to you in relation to the same.<br />
        <b>3.6</b> You understand that if you delete your account, or if we
          delete your account in accordance with these terms, you may lose
          access to any data previously associated with your account (including,
          without limitation, any Virtual Money associated with your account).<br />
        <b>3.7</b> YOU ACKNOWLEDGE AND AGREE THAT YOU SHALL HAVE NO OWNERSHIP OR
          OTHER PROPERTY INTEREST IN ANY ACCOUNT THAT YOU CREATE USING ANY OF
          OUR SERVICES. WE MAY SUSPEND, TERMINATE, MODIFY OR DELETE ANY OF THESE
          ACCOUNTS AT ANY TIME FOR ANY REASON OR NO REASON, WITH OR WITHOUT
          NOTICE TO YOU.<br />
        <b>3.8</b> Your account is personal to you and you are not entitled to
          transfer your account to any other person.<br />
        <h3>4 Virtual Money</h3>
        <b>4.1</b> To facilitate micro-transactions, our Services use a virtual
          currency, Picatag Credits (“Virtual Money"). You agree that once
          purchased Virtual Money have no monetary value and can only be
          exchanged for online services from us. You agree that Virtual Money
          are not transferable to anyone else and you will not transfer or
          attempt to transfer any Virtual Money to anyone else.<br />
        <b>4.2</b> You do not own Virtual Goods but instead you purchase a
          limited personal revocable licence to use them in exchange for our
          online services.<br />
        <b>4.3</b> You agree that all sales by us to you of Virtual Money are
          final, that we will not refund any transaction once it has been made.
          You have certain rights to withdraw from distance purchases; however,
          please note that when you purchase a licence to use Virtual Money from
          us, you acknowledge and agree that we will begin the provision of the
          Virtual Money to you promptly once your purchase is complete and
          therefore your right of withdrawal is lost at this point. For the
          purposes of this paragraph 4.3, a "purchase" is complete at the time
          our servers validate your purchase and the applicable Virtual Money
          are successfully credited to your account on our servers.<br />
        <b>4.4</b> We reserve the right to control, regulate, change or remove
          any Virtual Money without any liability to you at any time.<br />
        <b>4.5</b> We may revise the pricing for Virtual Money offered through
          the Services at any time. We may limit the total amount of Virtual
          Money that may be purchased at any one time, and/or limit the total
          amount of Virtual Money that may be held in your account in the
          aggregate. You are only allowed to obtain Virtual Money from us
          through the Services, and not in any other way.<br />
        <b>4.6</b> Depending on your platform, any Virtual Money purchased is
          purchased from your platform provider and such purchase will be
          subject to its terms of service and user agreement. Usage rights for
          each purchase may differ from item to item. If you are unsure about
          usage rights you should check with your platform before making a
          purchase. Unless otherwise shown, content available in any in-app
          store has the same age rating as the App.<br />
        <b>4.7</b> Without limiting paragraphs 3.7 or 6.1 if we suspend or
          terminate your account in accordance with these terms you may lose any
          Virtual Money that you may have and we will not compensate you for
          this loss or make any refund to you.<br />
        <b>4.8</b> The charge for any individual item you can purchase via our
          Website shall be as stated on our Website at the time you place the
          order, except in the case of obvious error. The charge is inclusive of
          all sales taxes and other charges. Depending on which bank you use,
          additional charges may be issued by your bank; we have no control over
          this and accept no liability in relation to the same. If you are
          unsure whether you will be subject to such additional charges then you
          should check with your bank before making a purchase via our Website.
          We accept payment via our payment processing partners by credit card
          and debit card only. Our payment processing partners may have their
          own terms and conditions and you should ensure you are in agreement
          with these prior to making any payment. If your transaction with our
          payment processing partners is not successful then your purchase will
          not be fulfilled. Upon the completion of a successful payment
          transaction then your purchase will be fulfilled to you as soon as
          possible - we will endeavour to fulfil your order immediately at the
          point of purchase.<br />
        <h3>5 User conduct and content</h3>
        <b>5.1</b> You must comply with the laws that apply to you in the
          location that you access our Services from. If any laws applicable to
          you restrict or prohibit you from using our Services, you must comply
          with those legal restrictions or, if applicable, stop accessing and/or
          using our Services.<br />
        <b>5.2</b> You promise that all the information you provide to us on
          accessing and/or using our Services is and shall remain true, accurate
          and complete at all times.<br />
        <b>5.3</b> Information, data, software, sound, photographs, graphics,
          video, tags, or other materials may be sent, uploaded, communicated,
          transmitted or otherwise made available via our Services by you or
          another user (“Content”). You understand and agree that all Content
          that you may be sent when using our Services, whether publicly posted
          or privately sent, is the sole responsibility of the person that sent
          the Content. This means that you, not us, are entirely responsible for
          all Content that you may upload, communicate, transmit or otherwise
          make available via our Services.<br />
        <b>5.4</b> You agree not to upload, communicate, transmit or otherwise
          make available any Content:
          <ul>
          <li>that is or could reasonably be viewed as unlawful, harmful,
            harassing, defamatory, libellous, obscene or otherwise
            objectionable;</li>
          <li>that is or could reasonably be viewed as invasive of another's
            privacy;</li>
          <li>that is likely to, or could reasonably be viewed as likely to
            incite violence or racial or ethnic hatred;</li>
          <li>which you do not have a right to make available lawfully (such as
              inside information, information which belongs to someone else or
              confidential information);</li>
          <li>which infringes any intellectual property right or other
            proprietary right of others;</li>
          <li>which consists of any unsolicited or unauthorised advertising,
            promotional materials, 'junk mail', 'spam', 'chain letters',
            'pyramid schemes' or any other form of solicitation; or</li>
          <li>which contains software viruses or any other computer code, files
            or programs designed to interrupt, destroy or limit the
            functionality of any computer software or hardware or
            telecommunications equipment.</li>
          </ul>
        <b>5.5</b> You agree that you will not:
          <ul>
          <li>use our Services to harm anyone or to cause offence to or harass
            any person;</li>
          <li>create more than one account per platform to access our Services;
          </li>
          <li>use another person or entity’s email address in order to sign up
            to use our Services;</li>
          <li>use our Services for fraudulent or abusive purposes (including,
            without limitation, by using our Services to impersonate any person
            or entity, or otherwise misrepresent your affiliation with a person,
            entity or our Services);</li>
          <li>disguise, anonymise or hide your IP address or the source of any
            Content that you may upload;</li>
          <li>use our Services for any commercial or business purpose or for the
            benefit of any third party or to send unsolicited communications;
          </li>
          <li>remove or amend any proprietary notices or other ownership
            information from our Apps or any other part of our Services;</li>
          <li>interfere with or disrupt our Services or servers or networks that
            provide our Services;</li>
          <li>attempt to decompile, reverse engineer, disassemble or hack any of
            our Services, or to defeat or overcome any of our encryption
            technologies or security measures or data transmitted, processed or
            stored by us;</li>
          <li>'harvest', 'scrape' or collect any information about or regarding
            other people that use our Services, including, but not limited to
            any personal data or information (including by uploading anything
            that collects information including but not limited to 'pixel tags'
            cookies, graphics interchange formats ('gifs') or similar items that
            are sometimes also referred to as 'spyware' or 'pcms' (passive
            collection mechanisms);</li>
          <li>sell, transfer or try to sell or transfer an account with us or
            any part of an account and/or Virtual Money;</li>
          <li>disrupt the normal flow of an App or otherwise act in a manner
            that is likely to negatively affect other user's ability to use the
            Service;</li>
          <li>disobey any requirements or regulations of any network connected
            to our Services;</li>
          <li>use our Services in violation of any applicable law or regulation;
          </li>
          <li>use our Services to cheat or design or assist in cheating, or to
            otherwise circumvent technological measures designed to control
            access to, or elements of, our Services, or to do anything else that
            a reasonable person is likely to believe is not within the spirit of
            fair play or these terms; or</li>
          <li>use our Services in any other way not permitted by these terms.
          </li>
          </ul>
        <b>5.6</b> We have the right to remove uploaded Content from our
          Services if we decide in our sole discretion that it results in or
          from a breach of any part of these terms, or that it may bring us or
          our Services into disrepute. However, you acknowledge that we do not
          actively monitor Content that is contributed by people that use our
          Services and we make no undertaking to do so.<br />
        <b>5.7</b> You are solely responsible for your interactions with other
          users of our Services.<br />
        <h3>6 Your breach of these terms</h3>
        <b>6.1</b> Without limiting any other remedies or any other paragraph
          of these terms, if we reasonably believe that you are in material
          breach of these terms (including by repeated minor breaches), we
          reserve the right to take any of the following actions, whether
          individually or in combination, and either with or without notice
          to you:
          <ul>
          <li>delete, suspend and/or modify your account or parts of your
            account;</li>
          <li>otherwise suspend and/or terminate your access to our Services;
          </li>
          <li>modify and/or remove any Virtual Money that may be associated with
            your account;</li>
          </ul>
          Without limitation, any breaches of paragraphs 4.1, 4.5, 5.4 or 5.5
          are likely to be considered material breaches.<br />
        <b>6.2</b> You agree to compensate us, according to law, for all losses,
          harm, claims and expenses that may arise from any breach of these
          terms by you.<br />
        <h3>7 Availability of the Services</h3>
        <b>7.1</b> Subject to the next sentence, we do not guarantee that any of
          our Services will be available or error-free at all times or at any
          given time. We will provide our Services in accordance with any
          legally required standards. In particular, in relation only to any
          Virtual Money or any other part of our Services which have been
          paid-for with real money, we warrant that they will substantially
          comply with the description provided by it at the point of purchase
          and be of satisfactory quality (in addition any related services
          provided through them will be provided with reasonable care and
          skill). We may change and update our Services in whole or in part
          without notice to you (provided always that any such changes do not
          result in material degradation in the functionality of any part of the
          Services which has been paid-for with real money). We make no warranty
          or representation regarding the availability of the Services which are
          provided free of charge (i.e. not paid-for with real money) and we
          reserve the right to modify or discontinue them in our sole discretion
          without notice to you, including for example, for economic reasons due
          to a limited number of users continuing to make use of them over time,
          for technical reasons (such as technical difficulties experienced by
          us or on the internet) or to allow us to improve user experience. We
          are not liable or responsible for any failure to perform, or delay in
          performance of, any of our obligations that is caused by events
          outside our reasonable control. If such circumstances result in
          material degradation in the functionality of the Services then any
          obligation you may have to make any payment to download, use or access
          them will be suspended for the duration of such period. We are
          entitled to modify or discontinue the Services or any part of them
          which are paid-for with real money in our sole discretion upon
          reasonable notice to you.
        <h3>8 Limitation of Liability</h3>
        <b>8.1</b> We accept liability for death or personal injury resulting
          from our negligence or that of our employees or agents, and for losses
          or harm caused by fraud by us or our agents, or any other liability
          which may not by law be excluded.<br />
        <b>8.2</b> We are not responsible for:
          <ul>
          <li>losses or harm not caused by our breach of these terms or
            negligence;</li>
          <li>losses or harm which are not reasonably foreseeable by you and us
            at the time of you agreeing to these terms and conditions including
            those losses which happen as a side effect of foreseeable losses.
            This could include loss of data, loss of opportunity, service
            interruption, computer or other device failure or financial loss;
          </li>
          <li>any damage that may be caused to any device on which you access or
            use any of our Apps or other Services that is caused in any way by
            our Services unless that damage is directly caused by our failure to
            exercise reasonable skill and care in the provision of the
            applicable Apps or other Services;</li>
          <li>any increase in loss or damage resulting from breach by you of any
            of these terms and conditions; or</li>
          <li>technical failures or the lack of availability of any of our
            Services where these are not within our reasonable control.</li>
          </ul>
        <b>8.3</b> For any losses or harm (other than those mentioned in
          paragraph 8.1 and subject to paragraph 8.2) in any period of twelve
          months, we will only be responsible for losses or harm which are a
          reasonably foreseeable consequence of our negligence or breach of
          these terms and conditions up to the value of the amount that you have
          paid to us in the 100 day period ending on the date of your claim.
          Losses and harm are foreseeable only where they could be contemplated
          by you and us at the time of you agreeing to these terms and
          conditions.<br />
        <b>8.4</b> Subject to paragraph 8.5 below and unless otherwise specified
          in these Terms, we do not give any warranty, express or implied, in
          relation to our Services and you acknowledge that your only right with
          respect to any problems or dissatisfaction with any of our Services is
          to discontinue your use of our Services.<br />
        <b>8.5</b> Any additional legal rights which you may have as a consumer
          remain unaffected by these terms.<br />
        <h3>9 Intellectual Property</h3>
        <b>9.1</b> You acknowledge that all copyright, trade marks, and other
          intellectual property rights in and relating to our Services (other
            than Content which is contributed and owned by users) is owned by or
            licensed to us.<br />
        <b>9.2</b> Whilst you are in compliance with these terms, we grant you a
          non-exclusive, non-transferable, personal, revocable limited licence
          to access and/or use our Services (but not any related object and
            source code) for your own personal private use, in each case
            provided that such use is in accordance with these terms. You agree
            not to use our Services for anything else. These terms also apply to
            any update or patches which we may release or make available for any
            of the Services and any such update or patch shall be deemed part of
            the Services for the purposes of these terms.<br />
        <b>9.3</b> YOU ACKNOWLEDGE AND AGREE THAT, OTHER THAN LICENSE GRANTED TO
          YOU BY THESE TERMS, YOU SHALL HAVE NO OWNERSHIP OR PROPERTY INTEREST
          IN ANY OF OUR SERVICES, INCLUDING WITHOUT LIMITATION ONLINE ACCOUNTS
          OR ANY VIRTUAL MONEY. You must not copy, distribute, make available to
          the public or create any derivative work from our Services or any part
          of our Services unless we have first agreed to this in writing.<br />
        <b>9.4</b> In particular, and without limiting the application of
          paragraph 9.3, you must not make use or available any cheats or
          technological measures designed to control access to, or elements of,
          our Services, including providing access to any Virtual Money, whether
          on a free of charge basis or otherwise.<br />
        <b>9.5</b> By submitting Content (as defined in paragraph 5.3) via our
          Services you:
          <ul>
          <li>are representing that you are fully entitled to do so;</li>
          <li>agree that we have no obligation to monitor or protect your rights
            in any Content that you may submit to us, but you do give us the
            right to enforce your rights in that Content if we want to,
            including but not limited to taking legal action (at our cost) on
            your behalf.</li>
          </ul>
        <b>9.6</b> You must not copy, distribute, make available to the public
          or create any derivative work from any Content belonging to any other
          user of our Services. If you believe that your intellectual property
          rights have been infringed by someone else over the internet, you may
          contact us by emailing the following information to
          ip-notice@picatag.com:<br />
          <ul>
          <li>a description of the intellectual property rights and an
            explanation as to how they have been infringed;</li>
          <li>a description of where the infringing material is located;</li>
          <li>your address, phone number and email address;</li>
          <li>a statement by you, made under penalty of perjury, that (i) you
            have a good-faith belief that the disputed use of material in which
            you own intellectual property rights is not authorised, and (ii) the
            information that you are providing is accurate, correct, and that
            you are authorised to act on behalf of the owner of an exclusive
            right that is allegedly infringed; and</li>
          <li>a physical or electronic signature of the person authorised to act
            on behalf of the owner of the exclusive right that has allegedly
            been infringed.</li>
          </ul>
        <h3>10 Privacy</h3>
         Our policies regarding the collection, use and disclosure of personal
         data when you use our Service and the choices you have associated with
         that data, are outlined in our <Link to='/privacy'>Privacy Policy
         </Link>, available at picatag.com/privacy.
        <h3>11 Links</h3>
        <b>11.1</b> We may link to third party websites or services from our
          Services. You understand that we make no promises regarding any
          content, goods or services provided by such third parties and we do
          not endorse the same. We are also not responsible to you in relation
          to any losses or harm caused by such third parties. Any charges you
          incur in relation to those third parties are your responsibility. You
          understand that when you provide data to such third parties you are
          providing it in accordance with their privacy policy (if any) and our
          own privacy policy does not apply in relation to that data.<br />
        <h3>12 Transferring these terms</h3>
        <b>12.1</b> We may wish to transfer all or a part of our rights or
          responsibilities under these terms to someone else without obtaining
          your consent. You agree that we may do so provided that the transfer
          does not significantly disadvantage you. You may not transfer any of
          the rights we give you under these terms unless we first agree to this
          in writing.<br />
        <h3>13 Entire agreement</h3>
        <b>13.1</b> These terms set out the entire agreement between you and us
          concerning our Services (as defined in paragraph 1) and they replace
          all earlier agreements and understandings between you and us.<br />
        <h3>14 Changes to these terms</h3>
        <b>14.1</b> You can find these terms at any time by visiting
          picatag.com/terms.<br />
        <b>14.2</b> We reserve the right to update these terms from time to time
          by posting the updated version at that address. We may do so for a
          number of reasons including without limitation because we change the
          nature of our products or services, for technical or legal reasons, or
          because the needs of our business have changed. You agree that if you
          do not accept any amendment to our terms then you shall immediately
          stop accessing and/or using our Services.<br />
        <h3>15 Severability</h3>
        <b>15.1</b> If any part of these terms is held to be invalid or
          unenforceable under any applicable local laws or by an applicable
          court, that part shall be interpreted in a manner consistent with
          applicable law to reflect as nearly as possible our original
          intentions and the remainder of these terms shall remain valid and
          enforceable. If it is not possible to interpret an invalid or
          unenforceable part of these terms in a manner consistent with
          applicable law, then that part shall be deemed deleted from these
          terms without affecting the remaining provisions of these terms.<br />
        <h3>16 Waivers of our rights</h3>
        <b>16.1</b> Our failure to exercise or enforce any of our rights under
          these terms does not waive our right to enforce such right. Any waiver
          of such rights shall only be effective if it is in writing and signed
          by us.<br />
        <h3>17 Complaints and dispute resolution</h3>
        <b>17.1</b> Most concerns can be solved quickly by contacting us at
          support@picatag.com.<br />
        <b>17.2</b> In the unlikely event that we cannot solve your concern and
          you wish to bring legal action against us these terms shall be
          governed by and construed in accordance with the laws of the
          Netherlands.<br />
        <h3>18 Jurisdiction and Applicable Law</h3>
        Our Apps and other Services are made available subject to these terms.
        This paragraph explains which laws apply to these terms.<br /><br />
        <b>18.1</b> The laws of the Netherlands shall govern the interpretation
          of these terms and apply to claims for breach of it, without reference
          to conflict of laws principles. All other claims, including claims
          regarding consumer protection laws, unfair competition laws, and
          claims in negligence and tort, will be subject to the laws of the
          country in which you are resident. If there is a dispute between us
          regarding these Terms and Conditions, then that dispute will be
          subject to the jurisdiction of the courts of the Netherlands unless
          the law in your country of residence allows you to choose the courts
          of that country for the dispute in question.<br />
        <h3>19 Questions about these terms</h3>
        <b>19.1</b> If you have any questions about these terms or our Services
          you may contact us by email at queries@picatag.com.<br /><br />
        These terms were last updated on 28/10/2019.
        </Container>
      </>
    );
  }
}

import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import App from './App';
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.render(
  (
    <Router>
      <App />
      <CookieConsent>
        This site uses cookies to analyse traffic, remember your preferences, and optimise your experience.<br />
        To learn more about how we use cookies, please read our <Link to='/privacy'>Privacy Policy</Link>
      </CookieConsent>
    </Router>
  ), document.getElementById("root")
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

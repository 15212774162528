import React, { Component } from "react";
import { Accordion, Container } from 'semantic-ui-react'

import qa from '../components/qa';
import "../css/Faq.css";

class Faq extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    var panels = [];
    var i = 0;

    qa.forEach(faq => {
      panels.push({
        key: i++,
        title: faq.q,
        content: faq.a
      })
    })

    return (
      <>
      <Container text>
      <h2>Frequently Asked Questions</h2>
      <p>These are some of the most frequently asked questions. If the answer you are looking for is not here, please don't hesitate to ask us.</p>
      <Accordion styled
        defaultActiveIndex={[]}
        panels={panels}
        exclusive={false}
        fluid
      />
      </Container>
      </>
    );
  }
}

export default Faq

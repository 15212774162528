import { API, graphqlOperation } from 'aws-amplify';
import { getUser, updateUser, createTransaction } from '../components/queries'

export const logTransaction = async (transactionUserId, credits, description, sesRef, sessionId) => {
  return API.graphql(graphqlOperation(getUser, { id: transactionUserId }))
  .then(user => {
    return API.graphql(graphqlOperation(updateUser, { input: {
        id:  transactionUserId,
        sk: "User",
        credits: user.data.getPicatag.credits + credits
      }}
    ))
  })
  .then(res => {
    return API.graphql(graphqlOperation(createTransaction, {
      input: {
        id: transactionUserId,
        sk: "Tran-" + Date.now(),
        credits: credits,
        description: description,
        sesRef: sesRef,
        sessionId: sessionId
      }}
    ))
  })
};

const qa = [{
  q: 'Why can\'t I register with my Facebook/Google/Amazon/OpenID account?',
  a: 'Privacy and Security. We understand that it may be convenient for you and it may be a way for us to validate your age, who you say you are and a whole lot of other things, but since we don\'t need to, we chose to save ourselves the headache of protecting all your personal information and you, the worry about what we may chose to do with it.'
}, {
  q: 'How does picture tagging work?',
  a: 'Picture files consist of two sections, header and data. Modern cameras use the header section to store camera settings, lighting information, even location information in every picture taken. That same section also has space for keywords or tags and that\'s where we store the tags we detect in your pictures.'
}, {
  q: 'Is this safe? Could my pictures get lost or damaged?',
  a: 'You have nothing to worry about. We work on copies of pictures that you upload to our servers and any alterations we make, are done on those copies. When your pictures are analysed and tagged, you will be able to download a zip file with the tagged copies. All that considered, don\'t take our word for it. Please make absolutely sure that you are happy with the downloaded copies before you do anything to your original pictures. A recent backup, as with all your work, is highly recommended.'
}, {
  q: 'Which applications/operating systems/software can make use of tags?',
  a: 'We started this service primarily to make is easy for people to search through their pictures by content. For example, searching for "snow" should return some of your winter holiday pictures. We have tested that this works with Windows Explorer on Windows and Finder on OS X. If you have found a new and innovative use for picture tags, please let us know as well!'
}, {
  q: 'Since you are modifying my pictures, will the image quality be affected?',
  a: 'No, it shouldn\'t be. We are indeed modifying the picture file but we only modify the header section where tags are stored. We do nothing to the data section where the image itself is stored. If in doubt, always double check that the quality of the tagged pictures has not been affected. If it has, please get in touch with us with some examples and help us improve our systems!'
}, {
  q: 'My pictures already have tags. will they be lost?',
  a: 'No, our system should add the detected tags to the list of existing tags that your pictures may have. While we have done extensive testing, working with Exif and XMP tags however is not a simple matter. If in doubt, always double check that the tagged pictures still contain all pre-existing Exif and XMP tags. If not, please get in touch with us with some examples and help us improve our systems!'
}, {
  q: 'Can I organise my pictures in albums and share them with my friends and family?',
  a: 'No. We are not an album or picture sharing site. We only store your pictures for a short period of time to minimise security and privacy concerns.'
}, {
  q: 'Why are my downloads only available for a few days?',
  a: 'For privacy, security and cost reasons. While we do our best to keep your pictures safe and private, there is no substitute to not having your pictures at all. That\'s why our systems are configured to automatically delete all the pictures you upload after 5 days. This would normally give you more than enough time to complete a tagging session and download your tagged pictures even if your laptop runs out of battery or you need to deal with something more important. It does of course also minimise the amount of storage needed to run our service and the cost savings of that are passed on to you.'
}, {
  q: 'Why don\'t the download links work when I share them with my friends and family?',
  a: 'For privacy and security reasons, all download links are both personal and temporary. They are created only for you and are only valid for a few minutes.'
}, {
  q: 'How does the "Precision" setting affect the Tags assigned to my pictures and which setting should I choose?',
  a: 'Detecting objects and scenes in an image is unfortunately not an exact science. A cat in a picture is sometimes hard to distinguish from a tiger, or a duck from a goose. On "Low Precision", both the "cat" and "tiger" tags will be added to the image. On "High Precision", one or none of the two will be used because we can\'t tell with confidence which of the two is the correct one. If in doubt, always use "Low Precision". After all, it\'s better to see Felix in your search results when searching for "tiger" than not finding his picture when searching for "cat".'
}/*, {
  q: 'Why do I need to select which tags are used?',
  a: 'Well, technically speaking, you don\'t need to, you just have the option to. Even if you know that you would always search for "Person" instead of "Human", there is absolutely no harm in having your pictures also tagged with "Human". If in doubt, select all detected tags.'
}*/];

export default qa

// WARNING: DO NOT EDIT. This file is automatically generated by CDK. It will be overwritten.

const awsmobile = {
  "aws_user_pools_id": "eu-west-1_qu0MB2eSA",
  "aws_user_pools_web_client_id": "1nf0e0rijij9ur54h4t14i9oru",
  "aws_appsync_region": "eu-west-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_appsync_graphqlEndpoint": "https://6dxju3xoi5fuln53u4nh7qcos4.appsync-api.eu-west-1.amazonaws.com/graphql",
  "aws_user_files_s3_bucket_region": "eu-west-1",
  "aws_user_files_s3_bucket": "picatag-prod-picatagprodsiteuploadsbucketee572392-vvlm3czscca1",
  "aws_project_region": "eu-west-1",
  "aws_cloud_logic_custom": [
    {
      "name": "RestApi",
      "region": "eu-west-1",
      "endpoint": "https://f4f1qmegc1.execute-api.eu-west-1.amazonaws.com/prod/"
    }
  ],
  "stripe_currency": "EUR",
  "stripe_key": "pk_live_RBAuFhT6cOokByKpA7PzGSWO00U830TCND",
  "aws_cognito_region": "eu-west-1",
  "aws_cognito_identity_pool_id": "eu-west-1:13981e89-5bb4-4116-8c1a-70d0b825413b"
}

export default awsmobile;
import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { Dropdown } from 'semantic-ui-react'
import { withAuthenticator } from "aws-amplify-react";
import aws_exports from '../aws-exports';

class StripeDropdown extends Component {
  constructor(props) {
    super(props);
    // This binding is necessary to make `this` work in the callback
    this.onClick = this.onClick.bind(this);

    this.state = {
      stripe: null,
    };
  }

  componentDidMount() {
    if(!document.getElementById('stripeJs')) {
      // componentDidMount only runs in a browser environment.
      // In addition to loading asynchronously, this code is safe to server-side render.

      // You can inject a script tag manually like this,
      // or you can use the 'async' attribute on the Stripe.js v3 <script> tag.
      const stripeJs = document.createElement('script');
      stripeJs.src = 'https://js.stripe.com/v3/';
      stripeJs.async = true;
      stripeJs.id = 'stripeJs';
      stripeJs.onload = () => {
        if (!this.state.stripe) {
          // The setTimeout lets us pretend that Stripe.js took a long time to load
          // Take it out of your production code!
          setTimeout(() => {
            this.setState({
              stripe: window.Stripe(aws_exports.stripe_key),
            });
          }, 500);
        }
      };
      document.body && document.body.appendChild(stripeJs);
    } else {
      if (!this.state.stripe) {
        // The setTimeout lets us pretend that Stripe.js took a long time to load
        // Take it out of your production code!
        setTimeout(() => {
          this.setState({
            stripe: window.Stripe(aws_exports.stripe_key),
          });
        }, 500);
      }
    }
  }

  componentWillUnmount() {
    this.state.stripe._controller._controllerFrame._removeAllListeners()
    this.state.stripe._controller._controllerFrame._iframe.remove()
//    var stripeJs = document.getElementById('stripeJs');
//    stripeJs && stripeJs.parentNode && stripeJs.parentNode.removeChild(stripeJs);

    var cframe = this.state.stripe && this.state.stripe._controller && this.state.stripe._controller._controllerFrame;
    cframe && cframe._removeAllListeners();

    const stripeIframes = [
      document.querySelectorAll('[name^=__privateStripeMetricsController]'),
      document.querySelectorAll('[name^=__privateStripeController]')
    ]

    stripeIframes.forEach(iframes =>
      iframes.forEach(iframe => {
        iframe.parentNode.removeChild(iframe)
      })
    )
  }

  async onClick(e, data) {
    const body = {
      charge: {
        amount: data.value,
        currency: aws_exports.stripe_currency,
        credits: data.credits,
        userId: this.props.userId,
        email: this.props.email,
        redirectSuccessUrl: window.location.href,
        redirectCancelUrl: window.location.href,
      },
    }

    await Auth.currentSession()
    .then(res => {
      const headers = { ApiAuth: res.idToken.jwtToken }
      return API.post('RestApi', 'session', { body: body, headers: headers });
    })
    .then(res => {
      console.log(res);
      console.log(res.session.id);
      console.log('Session created');

      this.state.stripe.redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: res.session.id
      }).then(function (result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      });
    })
    .catch(e => {
      console.log(e);
      console.log(body);
    })
  }

  render() {
    return (
      <Dropdown disabled={!this.state.stripe} inline text='Buy More'>
        <Dropdown.Menu>
          <Dropdown.Item onClick={this.onClick} value={50} credits={100} text='Buy 100 Credits for €0.50' />
          <Dropdown.Item onClick={this.onClick} value={100} credits={250} text='Buy 250 Credits for €1.00' />
          <Dropdown.Item onClick={this.onClick} value={300} credits={1000} text='Buy 1000 Credits for €3.00' />
          <Dropdown.Item onClick={this.onClick} value={600} credits={2500} text='Buy 2500 Credits for €6.00' />
          <Dropdown.Item onClick={this.onClick} value={1100} credits={5000} text='Buy 5000 Credits for €11.00' />
          <Dropdown.Item onClick={this.onClick} value={2000} credits={10000} text='Buy 10000 Credits for €20.00' />
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default withAuthenticator(StripeDropdown);

import React from "react";
import { Route, Switch } from "react-router-dom";

// Components
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

// Containers
import About from "./containers/About";
import Credits from "./containers/Credits";
import Download from "./containers/Download";
import Faq from "./containers/Faq";
import Help from "./containers/Help";
import Home from "./containers/Home";
import MyAccount from "./containers/MyAccount";
import MyPreferences from "./containers/MyPreferences";
import NotFound from "./containers/NotFound";
import Privacy from "./containers/Privacy";
import ResetPassword from "./containers/ResetPassword";
import Select from "./containers/Select";
import SignInUp from "./containers/SignInUp";
import Tag from "./containers/Tag";
import Terms from "./containers/Terms";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />

    <AppliedRoute path="/about" exact component={About} props={childProps} />
    <AppliedRoute path="/help" exact component={Help} props={childProps} />
    <AppliedRoute path="/faq" exact component={Faq} props={childProps} />
    <AppliedRoute path="/privacy" exact component={Privacy} props={childProps} />
    <AppliedRoute path="/terms" exact component={Terms} props={childProps} />

    <UnauthenticatedRoute path="/signin" exact component={SignInUp} props={childProps} />
    <UnauthenticatedRoute path="/signin/reset" exact component={ResetPassword} props={childProps} />

    <AuthenticatedRoute path="/myaccount" exact component={MyAccount} props={childProps} />
    <AuthenticatedRoute path="/mypreferences" exact component={MyPreferences} props={childProps} />
    <AuthenticatedRoute path="/tag/select" exact component={Select} props={childProps} />
    <AuthenticatedRoute path="/tag/credits" exact component={Credits} props={childProps} />
    <AuthenticatedRoute path="/tag/tag" exact component={Tag} props={childProps} />
    <AuthenticatedRoute path="/tag/download" exact component={Download} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;

import React, { Component } from "react";
import { Button, Container, Label, Item, Grid } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import "../css/Home.css";

export default class Home extends Component {

  render() {
    return (
      <>
      <Container className='Home'>
        <h1>picatag</h1>
        <Grid columns={2}>
        <Grid.Row>
        <Grid.Column>
        <Item.Group>
        <Item>
          <Item.Image size='medium' className='demoimage' floated='left' src='/images/swan.jpg' />
          <Item.Content>
            <Label tag>Animal</Label><br />
            <Label tag>Bird</Label><br />
            <Label tag>Outdoors</Label><br />
            <Label tag>Swan</Label><br />
            <Label tag>Water</Label><br />
          </Item.Content>
        </Item>
        <Item>
          <Item.Image size='medium' className='demoimage' floated='left' src='/images/beach.jpg' />
          <Item.Content>
            <Label tag>Beach</Label><br />
            <Label tag>Coast</Label><br />
            <Label tag>Outdoors</Label><br />
            <Label tag>Sea</Label><br />
            <Label tag>Water</Label><br />
          </Item.Content>
        </Item>
        <Item>
          <Item.Image size='medium' className='demoimage' floated='left' src='/images/geranium.jpg' />
          <Item.Content>
            <Label tag>Blossom</Label><br />
            <Label tag>Flower</Label><br />
            <Label tag>Geranium</Label><br />
            <Label tag>Plant</Label><br />
            <Label tag>Purple</Label><br />
          </Item.Content>
        </Item>
        </Item.Group>
        </Grid.Column>
        <Grid.Column>
          <h3>Make your pictures searchable!</h3>
          <p>Wouldn't it be nice if you could search through your own pictures
          by content, just like you do on your favourite search engine?
          No more digging through folders and folders? Now you can!</p>
          <p>Picture tags work just like tags on a blog. Think of them like
          categories that your pictures fit in, categories that you can use to
          find back your pictures when you need them. Manually sorting and
          tagging pictures however, can be a very, very tedious task.</p>
          <h3>Picatag to the rescue!</h3>
          <p>We can analyse your pictures, detect objects and scenes, and tag
          them so that you can find them by doing a simple search on your
          computer. It only takes a few clicks and you can have all your
          pictures enriched with search terms that are understood by Windows,
          Mac OS X, and depending on your settings, most Linux flavours.</p>
          <h3>Want to try?</h3>
          <p>Create an account and you can tag 100 of your pictures for free.
          We only need you to create an account so that your pictures stay safe
          and secure in there.</p>
          <p><Button as={Link} to='/tag/select'>Get Started</Button></p>
        </Grid.Column>
        </Grid.Row>
        </Grid>
      </Container>
      </>
    );
  }
}

import React, { Component } from "react";
import { Storage } from "aws-amplify";
import { API, graphqlOperation } from 'aws-amplify';
import { withAuthenticator } from "aws-amplify-react";
//import { Link } from 'react-router-dom'
import { Container, Grid, Modal, Button, Dropdown, Header, Segment, Progress, Image } from 'semantic-ui-react'
import Gallery from "react-photo-gallery";
import TagSteps from "../containers/TagSteps"
import TagSelector from "../containers/TagSelector"
import { setSessionStatus, createImage, getSessionImages } from '../components/queries'
import { logTransaction } from "../components/LogTransaction"
import { compareValues, arrayAddSubtract, promiseAllProgress, ddbTTL } from "../components/util"

import "../css/Tag.css";

class Tag extends Component {
  session = null;
  sessionImages = null;
  fetchingImages = false;
  uploadingImages = false;
  tagCounts = [];
  selectedTags = [];
  preferences = null;

  constructor(props) {
    super(props);

    this.preferences = JSON.parse(localStorage.getItem('picatag.userPreferences'));

    if (!this.preferences.favouriteTags) {
      this.preferences.favouriteTags = [];
      this.props.updateUserPreferences(this.preferences);
    }
    if (!this.preferences.minConfidence) {
      this.preferences.minConfidence = 1;
      this.props.updateUserPreferences(this.preferences);
    }

    this.session = JSON.parse(localStorage.getItem('picatag.currentSession'));

    if (this.session.status !== 'New' &&
      this.session.status !== 'Uploaded' &&
      this.session.status !== 'Analysed') {
      this.props.history.push("/tag/download");
    }

    this.selectedTags = JSON.parse(localStorage.getItem('picatag.selectedTags'));
    if (!this.selectedTags) { this.selectedTags = []; };

    this.state = {
      tagSortBy: 'Count',
      uploadProgress: 0,
      uploadTotal: 0,
      imageList: [],
      sessionImagesNeedRefresh: false,
    };
  }

  componentDidMount() {
    console.log('mount');
//    this.getSession();
//    this.getPreferences();
    this.uploadImages();
    this.loadSessionImages();
    //this.updateImageList();
  }

  componentDidUpdate(prevProps) {
    console.log('update');
//    this.getSession();
//    this.getPreferences();javascript remove property from object
    this.uploadImages();
    this.loadSessionImages();
    //this.updateImageList();
  }

  componentWillUnmount() {
    this.props.saveUserPreferences(this.props.user.id);
  }

  loadSessionImages() {
    if (!this.session || this.fetchingImages) {
      return;
    }

    if (!this.sessionImages && !this.state.sessionImagesNeedRefresh) {
      this.sessionImages = JSON.parse(localStorage.getItem('picatag.sessionImages'));
      this.updateImageList();
    }

    if (this.state.sessionImagesNeedRefresh || !this.sessionImages) {
      this.fetchingImages = true;
//      this.sessionImages = [];
      this.fetchSessionImages([], null)
      .then(res => {
        res.sort(compareValues('updatedAt', 'asc'))
        this.sessionImages = res;
        localStorage.setItem('picatag.sessionImages', JSON.stringify(res));
        this.fetchingImages = false;
        this.updateImageList();

        const pendingImages = this.sessionImages.find(image => image.status !== 'Analysed' && image.error === null) ? true : false;
        this.selectedTags = this.tagCounts.map(t => t.Name);
        localStorage.setItem('picatag.selectedTags', JSON.stringify(this.selectedTags));
        this.setState({ sessionImagesNeedRefresh: pendingImages });
      })
    }
  }

  fetchSessionImages(images, nextToken) {
    console.log('Fetching images from DB');
    return API.graphql(graphqlOperation(getSessionImages, {
      id: this.session.sessionId,
      limit: 100,
      nextToken: nextToken
    }))
    .then(res => {
      images.push(...res.data.listPicatags.items);
      if (res.data.listPicatags.nextToken) {
        return this.fetchSessionImages(images, res.data.listPicatags.nextToken)
      } else {
        return images;
      }
    })
  }

  updateImageList() {
    if (!this.sessionImages) { return; }
    console.log('Upadating imageList');

    this.tagCounts = [];
    var promises = [];
    this.sessionImages
    .filter(image => image.status === 'Analysed' && image.thumbnail !== null)
    .map(image => {
      const tagList = this.addToTagCounts(image).join(', ');
      return promises.push(Storage.get(image.thumbnail, { level: 'private' })
      .then(result => {
        return {
          src: result,
          alt: tagList,
          title: tagList,
          width: 1,
          height: 1,
          id: image.thumbnail.split('/').pop(),
        }
      }));
    });

    Promise.all(promises)
    .then(res => {
      this.setState({ imageList: res })
    })
  }

  addToTagCounts(image) {
    if (image.rtags) {
      const imageTags = JSON.parse(image.rtags);
      imageTags.Labels.filter(value => value.Confidence >= this.preferences.minConfidence).forEach(tag => {
        const index = this.tagCounts.findIndex(existingTag => existingTag.Name === tag.Name);
        if (index < 0) {
          this.tagCounts.push({ Name: tag.Name, Count: 1 });
        } else {
          this.tagCounts[index].Count++;
        }
      });
      return imageTags.Labels.filter(value => value.Confidence >= this.preferences.minConfidence).map(e => e.Name);
    } else { return []; }
  }

  updateTagLists() {
    this.tagCounts = [];
    this.setState(state => {
      const imagelist = this.sessionImages
      .filter(image => image.status === 'Analysed' && image.thumbnail !== null)
      .map(image => {
        const tagList = this.addToTagCounts(image).join(', ');
        var img = state.imageList.find(img => img.id === image.thumbnail.split('/').pop())
        img.alt = tagList;
        img.title = tagList;
        return img;
      });

      return( { imageList: imagelist });
    })
  }

  uploadProgress(context, n) {
    context.setState({ uploadProgress: n })
  }

  uploadImages() {
    if (!this.session ||
        this.session.status === 'Uploaded' ||
        this.props.selectedFiles.length === 0 ||
        this.uploadingImages) {
      return;
    }
    this.uploadingImages = true;
    console.log('uploadImages');
    var promises = [];
    this.props.selectedFiles.forEach(item =>
      promises.push(API.graphql(graphqlOperation(createImage, { input: {
        id: this.session.sessionId,
        sk: item.name,
        status: 'Uploaded',
        ttl: ddbTTL(5),
      } }))
      .then(res => {
        return Storage.put(this.session.sessionId + '/' + item.name, item, {
            level: 'private',
            contentType: item.type,
            customPrefix: {
              private: 'private-in/'
            }
        });
      })
      .catch(e => {
        console.log(e);
        console.log(this.sessionImages);
        console.log(item);
      })
    ));

    this.setState({ uploadTotal: promises.length })

    promiseAllProgress(this, promises, this.uploadProgress)
    .then(res => {
      return API.graphql(graphqlOperation(setSessionStatus, { input: {
        id: this.session.id,
        sk: this.session.sk,
        status: 'Uploaded',
        credits: this.props.selectedFiles.length,
//        ttl: ddbTTL(5),
      } }))
    })
    .then(res => {
      this.session = res.data.updatePicatag;
      localStorage.setItem('picatag.currentSession', JSON.stringify(this.session));
      return this.session;
    })
    .then(res => {
      return logTransaction(this.props.user.id, -this.session.credits, `Session ${new Date().toISOString()}`, this.session.sk, this.session.sessionId)
    })
    .then(res => {
      this.setState({sessionImagesNeedRefresh: true});
      this.props.refreshUser(this.props.user.id);
    })
    .catch((e) => { console.log(e); });
  }

  handleTagSelectionClick = (e, { name }) => {
    if (name === 'all') {
      this.selectedTags = this.tagCounts.map(t => t.Name);
    } else if (name === 'none') {
      this.selectedTags = [];
    } else if (name === 'fav') {
      this.selectedTags = this.preferences.favouriteTags.slice();
    }
    localStorage.setItem('picatag.selectedTags', JSON.stringify(this.selectedTags));
    this.setState({ activeItem: name })
  }

  handleCbChange = (e, { checked, label }) => {
    this.selectedTags = arrayAddSubtract(checked, this.selectedTags, [ label ]);
    localStorage.setItem('picatag.selectedTags', JSON.stringify(this.selectedTags));
    this.setState({ activeItem: '' })
  }

  handleFavChange = (e, { name, rating }) => {
    this.preferences.favouriteTags = arrayAddSubtract(
      rating === 1,
      this.preferences.favouriteTags,
      [ name ]
    );
    this.props.updateUserPreferences(this.preferences);
    this.setState({ activeItem: '' });
  }

  handleConfidenceChange = (e, { value }) => {
    this.preferences.minConfidence = value;
    this.props.updateUserPreferences(this.preferences);
    this.updateTagLists();
  }

  showZoom = (e, imageInfo) => {
    this.setState({ openZoom: true, openTag: false, zoomImageInfo: imageInfo });
  }
  closeZoom = () => this.setState({ openZoom: false, openTag: false })

  showTag = (tagName, e) => {
    this.setState({ openTag: true, openZoom: false, zoomTagName: tagName });
  }

  closeTag = () => this.setState({ openZoom: false, openTag: false })

  render() {
    this.tagCounts.sort(compareValues(this.state.tagSortBy, 'desc'));

    const { openZoom, openTag } = this.state

    //const loader = <div className="loader">Loading ...</div>;
    var tagsElements = [];

    const imageList = this.state.imageList;
    const tagImageList = this.state.zoomTagName ? imageList.filter(image => image.alt.split(', ').includes(this.state.zoomTagName)) : [];

    this.tagCounts.forEach(tag => {
      tagsElements.push(
          <TagSelector key={tag.Name} tagName={tag.Name} tagCount={tag.Count}
            checked={this.selectedTags.includes(tag.Name)} onChange={this.handleCbChange}
            rating={this.preferences.favouriteTags.includes(tag.Name) ? 1 : 0}
            onClick={this.showTag.bind(this, tag.Name)} onRate={this.handleFavChange} />
      );
    });

    const popupTags = this.state.zoomImageInfo ? this.state.zoomImageInfo.photo.title.split(', ').map(t => { return tagsElements.find(e => e.key === t) }) : [];

    const confidenceOptions = [
      { key: 1, text: 'High', value: 95 },
      { key: 2, text: 'Medium', value: 85 },
      { key: 3, text: 'Low', value: 1 },
    ]
    //console.log(this.sessionImages);
    const analysedProgress = this.sessionImages ? this.sessionImages.filter(image => {
      return (image.status === 'Analysed');
    }).length : 0;
    //console.log(analysedProgress);
    //console.log(this.session);
    const loading = (this.state.uploadTotal > 0 && this.state.uploadTotal > this.state.uploadProgress) || this.state.uploadTotal > analysedProgress

    return (
      <div className='Tag'>
      <TagSteps active='Tag' user={this.props.user} imageCount={imageList.length} selectedTags={this.selectedTags.length} />
      <Segment>
      <>
        {loading &&
          <Progress value={this.state.uploadProgress} total={this.state.uploadTotal} progress='ratio' >Uploaded</Progress>}
        {loading &&
          <Progress value={analysedProgress} total={this.state.uploadTotal} progress='ratio' label='Analysed' />}
        {loading ||
          <Segment compact className='tags'>
            <Container>
              <Header as='h3'>Tags</Header>
              Precision: <Dropdown inline
                onChange={this.handleConfidenceChange}
                options={confidenceOptions}
                defaultValue={this.preferences.minConfidence}
              />
              {/*
              <Menu compact>
                <Menu.Item name='all' active={this.state.activeItem === 'all'} onClick={this.handleTagSelectionClick}>
                  All
                </Menu.Item>
                <Menu.Item name='none' active={this.state.activeItem === 'none'} onClick={this.handleTagSelectionClick}>
                  None
                </Menu.Item>
                <Menu.Item name='fav' active={this.state.activeItem === 'fav'} onClick={this.handleTagSelectionClick}>
                  Favourite
                </Menu.Item>
              </Menu>*/}
            </Container>
            <Segment as={Grid} className='tagList' divided='vertically' columns='equal'>
              {this.state.tagsLoading || tagsElements}
            </Segment>
          </Segment>
        }
        {loading ||
          <Gallery className='photogallery' photos={imageList} targetRowHeight={180}
            onClick={this.showZoom} />
        }
        </>
      </Segment>

      <Modal closeIcon className='Tag' dimmer='inverted' open={openZoom} onClose={this.closeZoom}>
        <Modal.Content className='zoomImage' image>
          <div className='ui image'>
            <Image src={[ this.state.zoomImageInfo ? this.state.zoomImageInfo.photo.src : null ]} />
          </div>

          <Modal.Description>
            <Segment as={Grid} className='tagList' divided='vertically' columns='equal'>
              {popupTags}
            </Segment>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.closeZoom}>Close</Button>
        </Modal.Actions>
      </Modal>

      <Modal closeIcon className='Tag' dimmer='inverted' open={openTag} onClose={this.closeTag}>
        <Modal.Header>{this.state.zoomTagName}</Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description className='photogallery'>
            <Gallery className='photogallery' photos={tagImageList} targetRowHeight={180}
              onClick={this.showZoom} />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.closeTag}>Close</Button>
        </Modal.Actions>
      </Modal>

      </div>
    );
  }
}

export default withAuthenticator(Tag)

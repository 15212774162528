import React, { Component } from "react";
import { Grid, Container } from 'semantic-ui-react'
import "../css/About.css";

export default class About extends Component {

  render() {
    return (
      <>
      <Container text>
      <h2>About Us</h2>
      <Grid celled stackable className='About'>
        <Grid.Row columns={2}>
          <Grid.Column>
          <h3>We are a small team of compulsive picture sorters and organisers, with a technical background</h3>
          <p>Straight after the holiday - and if you promise to tell no-one, we'll also admit that sometimes even during the holiday - we set off on our quest to select, sort, group, categorise and file all pictures taken during those days of bliss.</p>
          <p>Since you are here, reading this, we can probably assume that we share at least one hobby.</p>
          </Grid.Column>
          <Grid.Column>
          <h3>Why Picatag?</h3>
          <p>Because we got tired of looking for that one particular picture for 15 minutes. <i>"It was our dog on the beach but when was that picture taken? Was it during a holiday, 3 years ago or just a Sunday out?"</i> We've all asked ourselves such a question, no mater how organised our pictures are.</p>
          <p>With the skills available to do something about it, we set off to solve our own problem at first. Then we realised that it would be selfish not to share, and just like that, Picatag was born.</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
          <h3>If it's a passion, why isn't it free?</h3>
          <p>Well, unfortunately, nothing in this life is free. Making this service available to the public costs us money. Not a lot of money, but every picture counts. The pricing structure we selected is closer to a tip jar than a money making business.</p>
          </Grid.Column>
          <Grid.Column>
          <h3>Why tags?</h3>
          <p>Picture tags are recognised by many popular picture sorting and album programs, as well as major parts of operating systems such as Windows Explorer and OS X Finder. When your pictures are tagged, you can find them by searching for the tags, just like you can search for tags on a blog.</p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      </Container>
      </>
    );
  }
}

// Images

export const getSessionImages = `query GetSessionImages($id: String!, $limit: Int, $nextToken: String) {
  listPicatags(id: $id, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sk
      userId
      status
      thumbnail
      rtags
      error
      updatedAt
    }
  }
}
`;

export const createImage = `mutation CreateImage($input: CreatePicatagInput!) {
  createPicatag(input: $input) {
    id
    sk
    userId
    status
  }
}
`;

export const setImageStatus = `mutation SetImageStatus($input: UpdatePicatagInput!) {
  updatePicatag(input: $input) {
    id
    sk
    userId
    status
  }
}
`;

// Sessions
export const createSession = `mutation CreateSession($input: CreatePicatagInput!) {
  createPicatag(input: $input) {
		id
		sk
		userId
    sessionId
    status
    credits
  }
}
`;

export const findRunningSession = `query FindRunningSession($id: String!) {
  listPicatags(id: $id, sk: {beginsWith: "Ses-"}, sortDirection: DESC, filter: {status: {ne: Zipped}} ) {
    items{
      id
      sk
      userId
      sessionId
      status
      credits
    }
  }
}
`;

export const setSessionStatus = `mutation SetSessionStatus($input: UpdatePicatagInput!) {
  updatePicatag(input: $input) {
    id
    sk
    userId
    sessionId
    status
    credits
  }
}
`;

// Stripe Payments
export const getStripePayments = `query GetStripePayments($id: String!, $limit: Int, $nextToken: String) {
  listPicatags(id: $id, sk: {beginsWith: "StripePay-"},
  sortDirection: DESC, limit: $limit, nextToken: $nextToken,
  filter: {status: {eq: Succesful}}) {
    items {
      id
      sk
      userId
      credits
      amountCents
      status
      updatedAt
    }
    nextToken
  }
}
`;

// Transactions
export const createTransaction = `mutation CreateTransaction($input: CreatePicatagInput!) {
	createPicatag(input: $input) {
		credits
    description
	}
}
`;

export const getTransactions = `query GetTransactions($id: String!, $limit: Int, $nextToken: String) {
  listPicatags(id: $id, sk: {beginsWith: "Tran-"},
  sortDirection: DESC, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sk
      userId
      credits
      description
      updatedAt
      sessionId
    }
    nextToken
  }
}
`;

// Users
export const getUser = `query GetUser($id: String!) {
  getPicatag(id: $id, sk: "User") {
    id
    sk
    userId
    idid
    email
    credits
    preferences
  }
}
`;

export const createUser = `mutation CreateUser($input: CreatePicatagInput!) {
  createPicatag(input: $input) {
    id
    sk
    userId
    idid
    email
    credits
    preferences
  }
}
`;

export const updateUser = `mutation UpdateUser($input: UpdatePicatagInput!) {
  updatePicatag(input: $input) {
		id
		sk
		userId
		idid
    email
    credits
		preferences
  }
}
`;

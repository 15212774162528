import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Link } from 'react-router-dom'
import { Button, Form, Message, Container } from 'semantic-ui-react'
import "../css/ResetPassword.css";

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: "",
      email: "",
      password: "",
      codeSent: false,
      confirmed: false,
      confirmPassword: "",
      isConfirming: false,
      isSendingCode: false
    };
  }

  validateCodeForm() {
    return this.state.email.length > 0;
  }

  validateResetForm() {
    return (
      this.state.code.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSendCodeClick = async event => {
    event.preventDefault();

    this.setState({ isSendingCode: true, error: false, errorMessage: '' });

    try {
      await Auth.forgotPassword(this.state.email);
      this.setState({ codeSent: true });
    } catch (e) {
      this.setState({ isSendingCode: false, error: true, errorMessage: e.message });
    }
  };

  handleConfirmClick = async event => {
    event.preventDefault();

    this.setState({ isConfirming: true, error: false, errorMessage: '' });

    try {
      await Auth.forgotPasswordSubmit(
        this.state.email,
        this.state.code,
        this.state.password
      );
      this.setState({ confirmed: true });
    } catch (e) {
      this.setState({ isConfirming: false, error: true, errorMessage: e.message});
    }
  };

  renderRequestCodeForm() {
    return (
      <Container text>
      <h2>Reset Password</h2>
      <p>Please enter your email address. A verification code that allows you to reset your password will be sent to that address.</p>
      <Form className="ResetPassword" onSubmit={this.handleSendCodeClick} loading={this.state.isSendingCode} error={this.state.error}>
        <Form.Input label='Email' type='email' placeholder='Email' id="email"
          onChange={this.handleChange} value={this.state.email} autoFocus />
        <Button type='submit' disabled={!this.validateCodeForm()}>Send Confirmation</Button>
        <Message error header='Sorry' content={this.state.errorMessage} />
      </Form>
      </Container>
    );
  }

  renderConfirmationForm() {
    return (
      <Container text>
      <h2>Reset Password</h2>
      <p>We just sent you a verification code at your registered email address. Please enter that, along with your new password to continue.</p>
      <Form className="ResetPassword" onSubmit={this.handleConfirmClick} loading={this.state.isConfirming} error={this.state.error}>
        <Form.Input label='Confirmation Code' type='tel' id="code"
          onChange={this.handleChange} value={this.state.code} autoFocus />
        <Form.Input label='New Password' type='password' placeholder='Password'
          id="password" onChange={this.handleChange} value={this.state.password} />
        <Form.Input label='Confirm Password' type='password' placeholder='Password'
          id="confirmPassword" onChange={this.handleChange} value={this.state.confirmPassword} />
        <Button type='submit' disabled={!this.validateResetForm()}>Confirm</Button>
        <Message error header='Sorry' content={this.state.errorMessage} />
      </Form>
      </Container>
    );
  }

  renderSuccessMessage() {
    return (
      <Container text>
      <h2>Your password has been reset</h2>
      <p><Link to="/signin">Click here to sign in with your new credentials.</Link></p>
      </Container>
    );
  }

  render() {
    return (
        !this.state.codeSent
          ? this.renderRequestCodeForm()
          : !this.state.confirmed
            ? this.renderConfirmationForm()
            : this.renderSuccessMessage()
    );
  }
}

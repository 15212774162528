import React, { Component } from "react";
import { Container, Label } from 'semantic-ui-react'
import "../css/TagSelector.css";

class TagSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <Container className='tagSelector'>
        <Label as='a' tag size='large' onClick={this.props.onClick}>
          {this.props.tagName}
          <Label.Detail>({this.props.tagCount})</Label.Detail>
        </Label>
      </Container>
    );
  }
}

export default TagSelector

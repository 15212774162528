import React, { Component } from "react";
import { Link } from 'react-router-dom'
import { Step, Button, Container, Label, Icon } from 'semantic-ui-react'
import "../css/TagSteps.css";

class TagSteps extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    const { active } = this.props;
    let nextEnabled = false;
    let nextTo = '';
    let backEnabled = false;
    let backTo = '';

    if (active === 'Select') {
      nextTo = '/tag/credits';
      nextEnabled = this.props.imageCount > 0;
    } else if (active === 'Credits') {
      const imgCount = this.props.imageCount;
      const creditCount = this.props.user ? this.props.user.credits : 0;
      nextTo = '/tag/tag';
      nextEnabled = imgCount <= creditCount && imgCount > 0;
      backTo = '/tag/select';
      backEnabled = true;
    } else if (active === 'Tag') {
      nextTo = '/tag/download';
      nextEnabled = this.props.selectedTags;
    } else if (active === 'Download') {
    }

    return (
      <>
      <Step.Group widths={4}>
        <Step active={active === 'Select'} icon='picture' title='Select' description='' />
        <Step active={active === 'Credits'} icon='money' title='Credits' description=''
          disabled={!(active === 'Credits' || active === 'Tag' || active === 'Download')} />
        <Step active={active === 'Tag'} icon='tags' title='Tag' description=''
          disabled={!(active === 'Tag' || active === 'Download')} />
        <Step active={active === 'Download'} icon='cloud download' title='Download' description=''
          disabled={!(active === 'Download')} />
      </Step.Group>
      <Container textAlign='center'>
      <Button floated='right' as={Link} to={nextTo} disabled={!nextEnabled}>Next</Button>
      <Button floated='left' as={Link} to={backTo} disabled={!backEnabled}>Back</Button>
      <Label>
        <Icon size='large' name='money' /> {this.props.user ? this.props.user.credits : 0}
      </Label>
      <Label>
        <Icon size='large' name='picture' /> {this.props.imageCount}
      </Label>
      </Container>
      </>
    );
  }

}

export default TagSteps

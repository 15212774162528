import React, { Component } from "react";
import { Container } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import "../css/Help.css";

export default class Help extends Component {

  render() {
    return (
      <>
      <Container text>
      <h2>Help</h2>
      <p>In this section you can find instructions and suggestions on how to use Picatag safely, efficiently and effectively.</p>
      <h3>Tagging Pictures</h3>
      <h4>The very short version</h4>
      <p>It takes four easy steps to have your pictures tagged. Select some pictures, click the "next" button three times and download your tagged pictures. Easy as pie!</p>
      <h4>The four steps explained</h4>
      <ul>
      <li><b>Select</b></li>
      <p>On this step you get to select the pictures you would like tagged. The pictures you select will appear on the page for a visual confirmation that you did indeed select the right pictures.</p>
      <p>You can remove individual pictures from the selection by clicking on the picture. Don't worry, you can't accidentally remove pictures. You will be asked to confirm before the picture is removed.</p>
      {/*<p>You can clear all the selected pictures at any moment by clicking the "Start Over" button on the top left of the page.</p>*/}
      <p>When you are happy with your selection, you can move to the next step by clicking on the "Next" button on the top right of the page.</p>
      <li><b>Credits</b></li>
      <p>Here you get an overview of your selection in numbers. The number of pictures you selected and the number of credits that will be needed to analyse and tag these pictures. At this point you can still go back to the previous step and ammend your selected pictures.</p>
      <p>If your credit balance is not enough to process all the selected pictures, you will be given the option to buy more credits. Unfortunately, buying credits interrupts the normal flow and for security-related, technical reasons, your list of selected pictures will be lost. You will have to start again at the previous step.</p>
      <p>When you have enough credits available, you will be able to move to the next step and start the "magic" process. Please note that when you click on the "Next" button      </p>
      <ul>
      <li>the calculated amount of credits will be deducted from your balance</li>
      <li>your pictures will be uploaded to our servers and analysed</li>
      <li>you will only be able to move forward from this step onwards</li>
      </ul>
      <li><b>Tag</b></li>
      <p>Depending on the number of pictures you selected, and the speed of your internet connection, this step may take a few moments to get going. Once your pictures are all uploaded, the analysis will start automatically and you will be shown your pictures and the tags that we detected.</p>
      <p>Your can click on a picture to see what tags we detected for it, or you can click on any of the tags to see which pictures will be tagged with it.</p>
      <p>The detection precision can also be set here. This setting affects how we assign tags to pictures. At low precision for example, we may also tag the picture of a lion with "cat". You can experiment with this setting all you like while checking your pictures until you are happy with the tag assignments.</p>
      <p>Please keep in mind that we will remember the precision setting you used and we will select it automatically on your next tagging round.</p>
      <li><b>Download</b></li>
      <p>That was it! All the "hard" decisions are behind you. Now all you need to do is wait for a few seconds while we apply the tags to the pictures and we zip them all up for an easy download.</p>
      <p>As soon aseverything is ready, the download link will appear on this page. Did you accidentally click away from the page and missed it? No worries, we got you covered. All your recent download links can also be found on the "Recent Transactions" section on <Link to='/myaccount'>My Account</Link>.</p>
      <p>Well done if you made it this far. We hope you enjoy the new possibilities your tagged pictures will create. We are certainly curious to hear about new and innovative uses for picture tagging.</p>
      </ul>
      <h4>Best Practices</h4>
      <ul>
      <li><b>Use small batches</b></li>
      <p>The biggest limitation of the tagging process is the speed and quality of your internet connection. While downloading may be fast, most internet connections have a limited upload capacity. If you already use any cloud storage service, you know what we mean.</p>
      <p>Our recommendation is to start by using small numbers of pictures until you are happy with the process and settings. Then you can start pushing the limits - primarily of your patience - with increasingly larger batches until you find a nice balance between practical numbers and time taken for the upload to complete.</p>
      <li><b>Sort your pictures in advance</b></li>
      <p>In our experience, it really helps to have your pictures sorted before you start. Selecting all the contents of a single folder makes it a lot easier to</p>
      <ul>
      <li>select the pictures you want to tag</li>
      <li>handle the pictures after downloading</li>
      <li>check and verify that all is well before doing anything to your originals</li>
      </ul>
      <li><b>Check your credit balance</b></li>
      <p>If you are not a big fan of sorting and you prefer to select pictures on the fly, then at least please make sure you check your credit balance before you start. There is nothing more annoying than having to find and select all pictures again because you don't have enough credits and you have to start over.</p>
      <li><b>Play it safe!</b></li>
      <p>Never do anything to your originals before you have double and tripple checked that you are absolutely happy with your downloaded, tagged copies of your pictures. You should check the downloaded pictures to make sure that</p>
      <ul>
      <li>none of the pictures are missing</li>
      <li>the image quality has not been degraded in any way</li>
      <li>all the Exif and XMP tags that you expected to be present are still present</li>
      </ul>
      <p>Just to be clear, we will accept ABSOLUTELY NO RESPONSIBILITY about how YOU handle YOUR pictures or other files on YOUR devices.</p>
      <p>If you are ever in doubt about uploading pictures with private, personal, sensitive, classified, secret or illegal content to our servers, please DON'T. We do our best to respect and protect your privacy, and we have absolutely no interest in looking at your pictures. We would rather save both you, and ourselves the headache of having to deal with unforseen situations.</p>
      </ul>
      <h3>Creating and Managing your Account</h3>
      <h4>Sign Up</h4>
      <p>We've done our best to keep the sign up process simple. To create an account, we ask for</p>
      <ul>
      <li>your name, simply to know how to address you in emails</li>
      <li>your email address to use as your username so that you have one less thing to remember when you return, and you don't have to try 42 different usernames before you find one that's not in use</li>
      <li>a password of a minimum length and with certain amount of complexity to ensure your account remains secure. If you ever forget this password, you can <Link to='/signin/reset'>reset it here</Link></li>
      </ul>
      <p>When you submit the sign up form, you will receive a confirmation code at the email address you entered. We do this to verify that you entered your email address correctly so that important future emails, such as payment receipts, will reach you. You will only be able to access your account after you have verified your email address.</p>
      <h4>Account Overview</h4>
      <p>You can get an overview of your account <Link to='/myaccount'>here</Link>. In this overview, you can see a list of your transactions (credits added or deducted from your credit balance), along with the associated download links, if they are still available. Please note that your tagged pictures remain available for download for a few days only.</p>
      <p>On the same page, you can also see a separate list of payments you have made and the amount of credits you bought each time.</p>
      <p>Other basic information on this page includes your email address and your available credits, with the option to buy more before your next tagging session.</p>
      <h4>Change Password</h4>
      <p>You can change your password at any time by following the <Link to='/signin/reset'>Forgot Password</Link> process on the <Link to='/signin'>Sign In</Link> page. Please note that those pages are only available when you are signed out.</p>
      <h4>Buying Credits</h4>
      <p>You can buy credits at any time from your <Link to='/myaccount'>account page</Link>. Simply select the amount of credits you wish to buy and you will automatically be redirected to the payment page of our payment processor. We use <a href='https://stripe.com' target='_blank' rel="noopener noreferrer">Stripe</a> for that. They are PCI-DDS, PSD2, SCM - and a whole lot of other acronyms - compliant.</p>
      <p>We choose to send you pay over there because that makes both us, and you, sleep better at night. We never get to see, or store, your card details. They do it for us and they notify us that you have paid for your credits.</p>
      <p>In case you are wondering, no, they don't have to call us, fax us, or send a carrier pigeon. We are told that your payment was complete before they even tell you. Your brand new, fresh credits should be available on your account before you are sent back to our site.</p>
      <p>If for whatever reason your credit balance isn't updated after a payment, and the payment doesn't show on your accout overview page, please let us know on support@picatag.com.</p>
      <h4>Preferences</h4>
      <p>The few settings that are available on this site, can be found on <Link to='/mypreferences'>your preferences</Link> page. Here you can opt in or out of certain types of communication, and you can set the default Precision level you would like to use during tagging (see Tag step under the Tagging Pictures section higher up).</p>
      <h4>Any Questions?</h4>
      <p>If we haven't managed to answer any of your questions here, please don't hesitate to contact us on support@picatag.com</p>
      </Container>
      </>
    );
  }
}

import React, { Component } from "react";
import { Storage } from "aws-amplify";
import { API, graphqlOperation } from 'aws-amplify';
import { withAuthenticator } from "aws-amplify-react";
import { Link } from 'react-router-dom'
import { Segment, Progress } from 'semantic-ui-react'
import TagSteps from "../containers/TagSteps"
import { setImageStatus, setSessionStatus, getSessionImages } from '../components/queries'
import { promiseAllProgress } from "../components/util"
import "../css/Download.css";

class Download extends Component {
  preferences = null;
  tagging = false;
  zipping = false;

  constructor(props) {
    super(props);

    this.preferences = JSON.parse(localStorage.getItem('picatag.userPreferences'));
    this.session = JSON.parse(localStorage.getItem('picatag.currentSession'));
    this.selectedTags = JSON.parse(localStorage.getItem('picatag.selectedTags'));
    this.sessionImages = JSON.parse(localStorage.getItem('picatag.sessionImages'));

    if (this.session.status === 'New') {
      this.props.history.push("/tag/select");
    }

    var pendingImages = true;
    if (this.sessionImages) {
      pendingImages = this.sessionImages.find(image => image.status !== 'Zipped' && image.error === null) ? true : false;
      pendingImages |= this.sessionImages.length === 0
    }

    this.state = {
      sessionImagesNeedRefresh: pendingImages,
      downloadReady: false,
      tagProgress: 0,
    };
  }

  componentDidMount() {
    console.log('mount');
    this.loadSessionImages();
    this.processImages();
    this.checkDownloads();
    this.getDownloadLink();
  }

  componentDidUpdate() {
    console.log('update');
    this.loadSessionImages();
    this.processImages();
    this.checkDownloads();
    this.getDownloadLink();
  }

  headers = { ApiAuth: this.props.authData.signInUserSession.idToken.jwtToken }

  processImages() {
    if (!this.sessionImages) { return Promise.resolve(); }

    return this.tagImages()
    .then(res => {
      return this.zipImages();
    })
    .catch(e => { console.log(e); });
  }

  tagProgress(context, n) {
    context.setState({ tagProgress: n })
  }

  tagImages() {
    if (this.session.status === 'Tagged' ||
        this.session.status === 'Zipped' ||
        this.tagging === true) { return Promise.resolve(); }

    this.tagging = true;
    console.log('tagging');

    const tagPromises = this.sessionImages.filter(image => image.status === 'Analysed').map(image => {
      const rTags = JSON.parse(image.rtags);
      const imageTags = rTags.Labels
      .filter(tag => this.selectedTags.includes(tag.Name))
      .filter(tag => tag.Confidence >= this.preferences.minConfidence)
      .map(tag => tag.Name)
      const keyIn = `private-in/${this.props.user.idid}/${image.id}/${image.sk}`;
      const keyOut = `private/${this.props.user.idid}/${image.id}/${image.sk}`;
      const body = {
        "keyIn": keyIn,
        "keyOut": keyOut,
        "tags": imageTags
      }

      return API.post('RestApi', 'tag', { body: body, headers: this.headers })
      .then(res => {
        image.status = 'Tagged';
        return API.graphql(graphqlOperation(setImageStatus, { input: {
            id: image.id,
            sk: image.sk,
            status: image.status,
          }}
        ))
      })
      .catch(e => {
        console.log(e);
        console.log(body);
      })
    })

    return promiseAllProgress(this, tagPromises, this.tagProgress)
    .then(res => {
      console.log('all done');
      localStorage.setItem('picatag.sessionImages', JSON.stringify(this.sessionImages));
      this.session.status = 'Tagged';
      return API.graphql(graphqlOperation(setSessionStatus, { input: {
          id: this.session.id,
          sk: this.session.sk,
          status: this.session.status,
        }}
      ))
      .then(res => {
        localStorage.setItem('picatag.currentSession', JSON.stringify(this.session));
        this.tagging = false;
      })
    })
  }

  zipImages() {
    if (this.session.status !== 'Tagged' || this.zipping === true) { return Promise.resolve(); }

    this.zipping = true;
    console.log('zipping');

    const body = {
      session: this.session.sessionId,
      sessionSk: this.session.sk,
      account: this.props.user.idid,
      userId: this.session.id
    }

    return API.post('RestApi', 'zip', { body: body, headers: this.headers })
    .then(res => {
      console.log('zip sent');
    })
    .catch(e => {
      console.log(e);
      console.log(body);
    })
  }

  loadSessionImages() {
    if (!this.session || this.fetchingImages) {
      return;
    }

    if (this.state.sessionImagesNeedRefresh || !this.sessionImages) {
      this.fetchingImages = true;
//      this.sessionImages = [];
      this.fetchSessionImages([], null)
      .then(res => {
//        console.log(res);
        this.sessionImages = res;
        localStorage.setItem('picatag.sessionImages', JSON.stringify(res));
        this.fetchingImages = false;

        const tagPendingImages = this.sessionImages.find(image => image.status === 'Analysed' && image.error === null)/* ? true : false*/;
        if (tagPendingImages) {
            this.session.status = 'Analysed';
        }

        if (this.sessionImages.length === 0) {
          this.session.status = 'New';
          localStorage.setItem('picatag.currentSession', JSON.stringify(this.session));
          this.props.history.push("/tag/select");
          return; // to avoid setting the state on unmounted component
        }

        const pendingImages = this.sessionImages.find(image => image.status !== 'Zipped' && image.error === null)/* ? true : false*/;
        //console.log(pendingImages);
        if (!pendingImages) {
          this.session.status = 'Zipped';
          localStorage.setItem('picatag.currentSession', JSON.stringify(this.session));
        }
        this.setState({ sessionImagesNeedRefresh: pendingImages });
      })
    }
  }

  fetchSessionImages(images, nextToken) {
    console.log('Fetching images from DB');
    return API.graphql(graphqlOperation(getSessionImages, {
      id: this.session.sessionId,
      limit: 100,
      nextToken: nextToken
    }))
    .then(res => {
      images.push(...res.data.listPicatags.items);
      if (res.data.listPicatags.nextToken) {
        return this.fetchSessionImages(images, res.data.listPicatags.nextToken)
      } else {
        return images;
      }
    })
  }

  checkDownloads() {
    if (this.session.status !== 'Zipped' || this.checkingDownloads || this.state.downloadReady) { return Promise.resolve(); }

    this.checkingDownloads = true;

    return Storage.list('downloads/', { level: 'private' })
    .then(result => {
//      console.log(result)
      this.checkingDownloads = false;
      const downloadReady = result.find(file => file.key === `downloads/${this.session.sessionId}.zip`) ? true : false;
//      console.log(downloadReady);
      this.setState({ downloadReady: downloadReady });

      this.props.newSession(this.props.user.id);
    })
    .catch(err => console.log(err));
  }

  getDownloadLink() {
    if (this.session.status !== 'Zipped' || this.gettingLink === true) { return Promise.resolve(); }

    this.gettingLink = true;

    return Storage.get(`downloads/${this.session.sessionId}.zip`, { level: 'private' })
    .then(result => {
//      console.log(result);
      this.setState({ downloadLink: result })
    })
  }

  render() {
//    console.log(this.sessionImages);
    const imgCount = this.sessionImages ? this.sessionImages.length : 0;
    const zipProgress = this.sessionImages ? this.sessionImages.filter(image => image.status === 'Zipped').length : 0;
    const tagProgress = zipProgress > 0 ? imgCount : this.state.tagProgress;
//    const loading = imgCount > tagProgress || imgCount > zipProgress;
    return (
      <>
      <TagSteps active='Download' user={this.props.user} imageCount={imgCount} />
        <Segment>
        <>
          {this.state.downloadReady ||
            <Progress value={tagProgress} total={imgCount} progress='ratio' label='Tagging' />
          }
          {this.state.downloadReady ||
            <Progress value={zipProgress} total={imgCount} progress='ratio' label='Bagging' />
          }
          {this.state.downloadReady &&
            <div>
              <p>Congratulations!</p>
              <p>Your tagged images are ready for <a href={this.state.downloadLink}>download</a>. This link will also be available for the next 5 days on <Link to='/myaccount'>your account</Link>.</p>
              <p><Link to='/tag/select'>Tag some more pictures?</Link></p>
            </div>
          }
        </>
        </Segment>
      </>
    );
  }
}

export default withAuthenticator(Download)

import React, { Component } from "react";
import { withAuthenticator } from "aws-amplify-react";
import { Link } from 'react-router-dom'
import { Table, Checkbox, Popup, Dropdown } from 'semantic-ui-react'

import "../css/MyPreferences.css";

class MyPreferences extends Component {
  preferences = null;

  constructor(props) {
    super(props);

    this.preferences = JSON.parse(localStorage.getItem('picatag.userPreferences'));

    this.state = {
    };
  }

  componentWillUnmount() {
    this.props.saveUserPreferences(this.props.user.id);
  }

  handleConfidenceChange = (e, { value }) => {
    console.log('handleConfidenceChange');
    this.preferences.minConfidence = value;
    this.props.updateUserPreferences(this.preferences);
  }

  handleCbFeaturesChange = (e, { checked }) => {
    console.log(checked);
    this.preferences.notifyOnFeatures = checked;
    this.props.updateUserPreferences(this.preferences);
    this.setState({ activeItem: '' })
  }

  handleCbPromotionChange = (e, { checked }) => {
    console.log(checked);
    this.preferences.notifyOnPromotion = checked;
    this.props.updateUserPreferences(this.preferences);
    this.setState({ activeItem: '' })
  }

  render() {
    const tcels = 1;

    const confidenceOptions = [
      { key: 1, text: 'High', value: 95 },
      { key: 2, text: 'Medium', value: 85 },
      { key: 3, text: 'Low', value: 1 },
    ]

    return (
      <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={tcels}>My Preferences</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'><Link to='/myaccount'>My Account</Link></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell><b>Communication:</b></Table.Cell>
            <Table.Cell>
              <Checkbox onChange={this.handleCbFeaturesChange} checked={this.preferences.notifyOnFeatures} label='I would like to be informed when new picatag features are available' /><br />
              <Checkbox onChange={this.handleCbPromotionChange} checked={this.preferences.notifyOnPromotion} label='I would like to be informed when new picatag promotions or special offers are available' /><br />
              <Popup
                trigger={<Checkbox disabled label='I would like to be informed when new third party promotions or special offers are available' />}
                content="Sorry, we don't share any of your details with third parties. This option is not available."
              /><br />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell><b>Tag Settings:</b></Table.Cell>
            <Table.Cell>
              Default Precision: <Dropdown inline
                onChange={this.handleConfidenceChange}
                options={confidenceOptions}
                defaultValue={this.preferences.minConfidence}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      </>
    );
  }
}

export default withAuthenticator(MyPreferences)

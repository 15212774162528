import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Grid, Header, Menu, Icon, Dropdown, Image } from 'semantic-ui-react';
import "./css/App.css";
//import './css/bootstrap.css';
import Routes from "./Routes";
import Amplify, { Auth } from "aws-amplify";
import aws_exports from './aws-exports';
import { API, graphqlOperation } from 'aws-amplify';
import { getUser, updateUser } from './components/queries'
import { findRunningSession, createSession } from './components/queries'
//import { ddbTTL } from "./components/util"

Amplify.configure(aws_exports);
Amplify.Logger.LOG_LEVEL = 'INFO';

const uuidv4 = require('uuid/v4');

// Stripe Privacy Protector
const config = { attributes: false, childList: true, subtree: false }

const filterNodes = (nl: NodeList) => Array.from(nl)
  .filter(b => b.nodeName === 'IFRAME')
  .filter((b: HTMLIFrameElement) => b.name.match(/privateStripeMetricsController/g))

const mutationCallback = (a: MutationRecord[]) => a.reduce((acc, curr) =>
  curr.type === 'childList'
    ? [...acc, ...filterNodes(curr.addedNodes)]
    : acc, [])
  .forEach(a => a.remove())

new MutationObserver(mutationCallback)
  .observe(document.body, config)
// Stripe Privacy Protector

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      user: null,
      selectedFiles: [],
    };
  }

  async componentDidMount() {
    try {
      const session = await Auth.currentSession();
      await this.userHasAuthenticated(true, session.idToken.payload.sub);
    }
    catch(e) {
      if (e !== 'No current user') {
        console.log(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  newSession = async (userId) => {
//    await this.clearSession();
    await this.setupSession(userId);
  }

  userHasAuthenticated = async (authenticated, userId) => {
    if (authenticated) {
      await this.setupSession(userId);
    } else {
      await this.clearSession();
    }
    this.setState({ isAuthenticated: authenticated });
  }

  loadUser(userId) {
    return API.graphql(graphqlOperation(getUser, { id: userId }))
    .then(res => {
      var preferences = JSON.parse(res.data.getPicatag.preferences);
      if (!preferences) { preferences = {}; };
      localStorage.setItem('picatag.userPreferences', JSON.stringify(preferences));
      delete res.data.getPicatag.preferences;
      this.setState({ user: res.data.getPicatag });
      return res;
    })
  }

  setupSession(userId) {
    this.updateSelectedFiles([]);
    return this.loadUser(userId)
    .then(res => { return this.getSession(userId); })
    .then(res => { if (res.status === 'New') { localStorage.removeItem('picatag.sessionImages'); }; return res; })
    .then(res => { localStorage.setItem('picatag.currentSession', JSON.stringify(res)); return res; })
    .catch(e => { console.log(e); })
  }

  clearSession() {
    this.setState({ user: null, session: null });
    this.updateSelectedFiles([]);

    localStorage.removeItem('picatag.currentSession');
    localStorage.removeItem('picatag.sessionImages');
    localStorage.removeItem('picatag.userPreferences');
    localStorage.removeItem('picatag.selectedTags');

    this.props.history.push("/");
  }

  updateSelectedFiles = selectedFiles => {
    this.setState({ selectedFiles: selectedFiles });
  }

  refreshUser = userId => {
    return this.saveUserPreferences(userId)
    .then(res => { this.loadUser(userId); })
  }

  updateUserPreferences(preferences) {
    preferences.needSaving = true;
    localStorage.setItem('picatag.userPreferences', JSON.stringify(preferences));
  }

  saveUserPreferences(userId) {
    var preferences = JSON.parse(localStorage.getItem('picatag.userPreferences'));
    if (preferences.needSaving) {
      preferences.needSaving = false;
      return API.graphql(graphqlOperation(updateUser, { input: {
          id:  userId,
          sk: "User",
          preferences: JSON.stringify(preferences),
        }}
      ))
      .then(res => { localStorage.setItem('picatag.userPreferences', JSON.stringify(preferences)); })
      .catch(e => { console.log(e); })
    } else {
      return new Promise(function(resolve, reject) {
          resolve();
      });
    }
  }

  getSession(userId) {
    return API.graphql(graphqlOperation(findRunningSession, { id: userId }))
    .then(res => {
      if (res.data.listPicatags.items.length === 0) {
        return API.graphql(graphqlOperation(createSession, {
          input: {
            id: userId,
            sk: 'Ses-' + Date.now(),
            sessionId: uuidv4(),
            status: 'New',
//            ttl: ddbTTL(5),
          }}
        )).then(res => {
          return res.data.createPicatag;
        });
      } else {
        return res.data.listPicatags.items[0];
      }
    })
    .catch(e => {console.log(e);})
  }

  handleLogout = async (e, {value} ) => {
    this.state.user && await this.saveUserPreferences(this.state.user.id);
    await Auth.signOut();

    await this.userHasAuthenticated(false);
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      user: this.state.user,
      refreshUser: this.refreshUser,
      newSession: this.newSession,
      selectedFiles: this.state.selectedFiles,
      updateSelectedFiles: this.updateSelectedFiles,
      updateUserPreferences: this.updateUserPreferences,
      saveUserPreferences: this.saveUserPreferences,
    };

    return (
      !this.state.isAuthenticating &&
      <Grid padded className='App'>
        <Grid.Row columns={2} className='header'>
          <Grid.Column>
            <Header as='h2' >
              <Image src='/logo.gif' spaced='right' as={Link} to='/' />
              <Link to="/">picatag</Link>
              <Header.Subheader>Make your pictures searchable!</Header.Subheader>
            </Header>
          </Grid.Column>

          <Grid.Column>
            <Menu compact floated="right">
            { this.state.isAuthenticated ?
            <Dropdown simple direction='left' icon='user' className="accountIcon">
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/tag/select"><Icon name="tags" />Go Tagging</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item as={Link} to="/myaccount"><Icon name="user" />My Account</Dropdown.Item>
                <Dropdown.Item as={Link} to="/mypreferences"><Icon name="settings" />My Preferences</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={this.handleLogout}><Icon name="sign out" />Log Out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            :
              <Menu.Item name="SignIn" as={Link} to="/signin">Sign In</Menu.Item>
            }
            </Menu>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className='body'>
          <Grid.Column>
            <Routes childProps={childProps} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='footer'>
          <Grid.Column textAlign='center'>
            <Link to='/faq'>FAQ</Link>{' - '}
            <Link to='/help'>Help</Link>{' - '}
            <Link to='/about'>About</Link>{' - '}
            <Link to='/privacy'>Privacy</Link>{' - '}
            <Link to='/terms'>Terms</Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}


export default withRouter(App);

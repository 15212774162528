import React, { Component } from "react";
import { withAuthenticator } from "aws-amplify-react";
import { Segment, Container, List, Modal, Button } from 'semantic-ui-react'
import TagSteps from "../containers/TagSteps"
import Dropzone from 'react-dropzone'
import Gallery from "react-photo-gallery";
import "../css/Select.css";

class Select extends Component {
  session = null;
  remMessage = 'Click to Remove'

  constructor(props) {
    super(props);

    this.session = JSON.parse(localStorage.getItem('picatag.currentSession'));

    if (this.session.status === 'Uploaded' || this.session.status === 'Analysed') {
      this.props.history.push("/tag/tag");
    }
    if (this.session.status === 'Tagged' || this.session.status === 'Zipped') {
      this.props.history.push("/tag/download");
    }

    this.state = {
      acceptedFiles: props.selectedFiles,
      rejectedFiles: [],
      erroredFiles: [],
      imageList: props.selectedFiles.map(file => { return { src: URL.createObjectURL(file), name: file.name, width: 1, height: 1, alt: this.remMessage, title: this.remMessage } }),
    };
  }

  componentWillUnmount() {
    this.props.updateSelectedFiles(this.state.acceptedFiles);
  }

  addToFileList(existingFiles, newFiles) {
    const erroredFiles = this.state.erroredFiles;
    newFiles.forEach(file => {
      if (!existingFiles.find(existingFile => existingFile.path === file.path) &&
          !erroredFiles.find(erroredFile => erroredFile.path === file.path)) {
        existingFiles.push(file);
      }
    });
    return existingFiles;
  }

  getRejectedFiles = (newfiles) => {
    this.setState(state => {
      const rejectedFiles = this.addToFileList(state.rejectedFiles, newfiles);
      return { rejectedFiles: rejectedFiles };
    });
  }

  getAcceptedFiles = (newfiles) => {
    this.setState(state => {
      const acceptedFiles = this.addToFileList(state.acceptedFiles, newfiles);
      const imageList = acceptedFiles.map(file => { return { src: URL.createObjectURL(file), name: file.name, width: 1, height: 1, alt: this.remMessage, title: this.remMessage} });

      return { acceptedFiles: acceptedFiles, imageList: imageList };
    });
  }

  handleImageLoaded = (file, e) => {
    console.log(`done loading ${file.name}`);
  }

  handleImageErrored = (file, e) => {
    this.setState(state => {
      const files = state.acceptedFiles.filter(function( obj ) {
        return !(obj.path === file.path);
      });

      this.props.updateSelectedFiles(files);
      state.imageList[file.name] = false;
      return { acceptedFiles: files, imageList: state.imageList };
    });
    console.log(`error loading ${file.name}`);
  }

  removeImage(name) {
    this.setState(state => {
      const acceptedFiles = state.acceptedFiles.filter(file => file.name !== name);
      const imageList = acceptedFiles.map(file => { return { src: URL.createObjectURL(file), name: file.name, width: 1, height: 1, alt: this.remMessage, title: this.remMessage} });

      return { acceptedFiles: acceptedFiles, imageList: imageList, openAskRemove: false };
    });
  }

  handleImageClick = (e, imageInfo) => {
    if (this.state.removeWithoutAsking) {
      this.removeImage(imageInfo.photo.name);
    }
    else {
      this.setState({ openAskRemove: true, removeImage: imageInfo.photo.name });
    }
  }

  closeAskRemove = () => this.setState({ openAskRemove: false })
  closeAskRemoveYes = () => {
    this.removeImage(this.state.removeImage);
  }

  handleRemoveAllClick = () => {
    this.setState({ openAskRemoveAll: true });
  }

  closeAskRemoveAll = () => this.setState({ openAskRemoveAll: false })
  closeAskRemoveAllYes = () => {
    this.setState({ acceptedFiles: [], imageList: [], openAskRemoveAll: false })
  }



  render() {
    const { openAskRemove, openAskRemoveAll } = this.state

    return (
      <div className='Select'>
      <TagSteps active='Select' user={this.props.user} imageCount={this.state.imageList.length} />
      <Segment>
        <Dropzone accept='image/jpeg, image/png' maxSize={14000000}
              onDropAccepted={this.getAcceptedFiles}
              onDropRejected={this.getRejectedFiles}>
          {({getRootProps, getInputProps}) => (
            <section>
              <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some pictures here,<br />or click to select pictures</p>
              </div>
            </section>
          )}
        </Dropzone>
      </Segment>
      {this.state.rejectedFiles.length > 0 &&
        <Container>
          <p>Unfortunately, these files are either too large or of unsupported type and can not be included.</p>
          <List>
            {this.state.rejectedFiles.map(file => { return <List.Item>{file.name} ({(file.size/1000000).toFixed(2)}MB)</List.Item> })}
          </List>
          <p>Please note that only jpeg (*.jpg or *.jpeg) and PNG (*.png) images are currently supported and the maximum size per image is 14MB.</p>
          <p> </p>
        </Container>
      }
      {this.state.imageList.length > 0 &&
        <Container textAlign='center'>
        <Button size='mini' onClick={this.handleRemoveAllClick}>Remove all and start over</Button>
        </Container>
      }
      <Gallery className='photogallery' photos={this.state.imageList} onClick={this.handleImageClick} targetRowHeight={180} />
      <Modal size='tiny' dimmer='inverted' open={openAskRemove} onClose={this.closeAskRemove}>
        <Modal.Header>Remove Picture</Modal.Header>
        <Modal.Content>
          Remove {this.state.removeImage} from your selection?
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.closeAskRemoveYes}>Yes</Button>
          <Button onClick={this.closeAskRemove}>No</Button>
        </Modal.Actions>
      </Modal>
      <Modal size='tiny' dimmer='inverted' open={openAskRemoveAll} onClose={this.closeAskRemoveAll}>
        <Modal.Header>Remove All Pictures</Modal.Header>
        <Modal.Content>
          Remove all pictures from your selection and start over?
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.closeAskRemoveAllYes}>Yes</Button>
          <Button onClick={this.closeAskRemoveAll}>No</Button>
        </Modal.Actions>
      </Modal>
      </div>
    );
  }
}

export default withAuthenticator(Select)
